import React, { FC } from 'react'
import {
  Modal,
  Button,
  TextField,
  Fade,
  CircularProgress,
  Container,
  Card,
  CardContent,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useMutation, queryCache } from 'react-query'
import { useForm, Controller } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import CloseIcon from '@material-ui/icons/Close'

import { QUERY_KEYS } from '@api'
import { useGlobalStyles } from '@styles'
import { ErrorResponse } from '@types'
import { useCustomSnackbar } from '@hooks'

import { addTagApi } from './api'
import { useStyles } from '../../styles'

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  isActive: yup.boolean(),
})

interface Props {
  isOpen: boolean
  handleClose: () => void
}

type TagsFormType = {
  name: string
  isActive: boolean
}

export const AddTagModal: FC<Props> = ({ isOpen, handleClose }) => {
  const globalClasses = useGlobalStyles()
  const classes = useStyles()
  const snackbar = useCustomSnackbar()

  const defaultValues = {
    name: '',
    isActive: false,
  }

  const { handleSubmit, errors, control } = useForm<TagsFormType>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })

  const [add, { isLoading }] = useMutation(addTagApi.addTag, {
    onSuccess: async () => {
      await queryCache.invalidateQueries(QUERY_KEYS.TAGS)
      snackbar.showSuccess('¡Guardado con éxito!')
    },
    onError: (error: ErrorResponse) => {
      snackbar.showError(error.message)
    },
  })

  const onSubmit = (data: TagsFormType) => {
    add(data)
  }

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        handleClose()
      }}
      className={globalClasses.modal}
    >
      <Fade in={isOpen} timeout={500}>
        <Container className={globalClasses.modalContainer}>
          <Card className={globalClasses.modalCard}>
            <CloseIcon
              className={globalClasses.modalCloseIcon}
              onClick={() => {
                handleClose()
              }}
            />
            <CardContent>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className={globalClasses.formGroup}>
                  <Controller
                    name="name"
                    control={control}
                    as={
                      <TextField
                        label="Nombre"
                        placeholder="Ingresar nombre de etiqueta"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        autoComplete="off"
                      />
                    }
                  />
                  {errors.name && <Alert severity="error">Este campo es obligatorio</Alert>}
                </div>
                <div className={classes.actions}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.saveButton}
                  >
                    Agregar etiqueta
                    {isLoading && (
                      <CircularProgress
                        className={classes.progress}
                        color="inherit"
                        disableShrink
                        size={18}
                      />
                    )}
                  </Button>
                </div>
              </form>
            </CardContent>
          </Card>
        </Container>
      </Fade>
    </Modal>
  )
}
