import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: 20,
  },
  divider: {
    marginBottom: 20,
    marginTop: 20,
  },
  editor: {
    height: 200,
    marginBottom: 48,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
  },
  actionButton: {
    marginRight: 20,
  },
  accordionHading: {
    fontSize: theme.typography.pxToRem(15),
    flexShrink: 0,
  },
  accordionSecondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    marginLeft: 10,
  },
}))

export { useStyles }
