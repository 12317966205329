import React, { FC } from 'react'
import { Box } from '@material-ui/core'

import { Search, CategoriesFilter } from './components'
import { useStyles } from './styles'

interface Props {
  handleTextChange: (inputText: string) => void
}

export const NavFilter: FC<Props> = ({ handleTextChange }) => {
  const classes = useStyles()

  return (
    <>
      <Box className={classes.box}>
        <CategoriesFilter />
      </Box>
      <Box className={classes.box} alignSelf="flex-end" style={{ marginBottom: 25 }}>
        <Search handleTextChange={handleTextChange} />
      </Box>
    </>
  )
}
