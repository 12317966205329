import React, { FC } from 'react'
import { Paper } from '@material-ui/core'
import { useHistory } from 'react-router'
import { queryCache, useMutation } from 'react-query'

import { QUERY_KEYS } from '@api'
import { useCustomSnackbar } from '@hooks'
import { ErrorResponse } from '@types'

import { useStyles } from './styles'
import { AddWebinarsApi } from './api'
import { WebinarForm } from '../components'
import { Header } from '../../components'

export const AddWebinar: FC = () => {
  const classes = useStyles()
  const { push } = useHistory()
  const snackBar = useCustomSnackbar()

  const [add, { isLoading }] = useMutation(AddWebinarsApi.addWebinar, {
    onSuccess: async ({ webinar }) => {
      await queryCache.invalidateQueries(QUERY_KEYS.WEBINARS)
      snackBar.showSuccess('¡Guardado con éxito!')
      push(`edit/${webinar.id}`)
    },
    onError: (error: ErrorResponse) => {
      snackBar.showError(error.message)
    },
  })
  return (
    <>
      <Header title="Agregar Webinar" />

      <Paper className={classes.paper}>
        <WebinarForm handleSubmit={add} isLoading={isLoading} />
      </Paper>
    </>
  )
}
