import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    top: '50% !important',
    left: '50% !important',
    transform: 'translate(-50%, -50%) !important',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: 'center',
  },
  closeIcon: {
    cursor: 'pointer',
    position: 'absolute',
    top: '10px',
    right: '15px',
  },
  header: {
    marginBottom: '30px',
  },
  actions: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  saveButton: {
    width: '100%',
    marginBottom: 10,
    marginRight: 10,
  },
  progress: {
    marginLeft: 10,
  },
}))

export { useStyles }
