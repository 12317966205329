import { api } from '@utils'
import { TherapeuticArea } from '@types'

interface GetTherapeuticAreasResponse {
  therapeuticAreas: TherapeuticArea[]
}

const getTherapeuticAreas = async (): Promise<TherapeuticArea[]> => {
  const response = await api.get<GetTherapeuticAreasResponse>('/therapeutic-areas')
  return response.data.therapeuticAreas
}
export const GlobalTherapeuticAreasApi = { getTherapeuticAreas }
