import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  title: {
    marginBottom: 20,
  },
  divider: {
    marginBottom: 20,
    marginTop: 20,
  },
  editor: {
    height: 200,
    marginBottom: 48,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
  },
  actionButton: {
    marginRight: 20,
  },
})

export { useStyles }
