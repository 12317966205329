import { api } from '@utils'
import { Speaker } from '@types'

export type UpdateStatusPayload = {
  id: number
  isActive: boolean
}
interface GetSpeakersPayload {
  page?: number
  limit?: number
  name?: string
  lastName?: string
  specialty?: string
  isActive?: boolean
  allWebinars?: boolean
}

interface GetSpeakersResponse {
  speakers: Speaker[]
  count: number
}

interface UpdateStatusResponse {
  speaker: Speaker
}

const getSpeakers = async (
  _: string,
  { page = 0, limit = 10, name = '', lastName = '', specialty = '', isActive }: GetSpeakersPayload,
): Promise<GetSpeakersResponse> => {
  const params = {
    name: name || undefined,
    lastName: lastName || undefined,
    specialty: specialty || undefined,
    offset: page * limit,
    allWebinars: true,
    limit,
    isActive,
  }
  const response = await api.get<GetSpeakersResponse>('/speakers', { params })

  return response.data
}

const updateStatus = async ({
  id,
  isActive,
}: UpdateStatusPayload): Promise<UpdateStatusResponse> => {
  const formData = new FormData()
  formData.append('speaker', JSON.stringify({ isActive }))

  try {
    const response = await api.put<UpdateStatusResponse>(`/speakers/${id}`, formData)
    return response.data
  } catch (err) {
    throw err.response.data.error
  }
}

export const GlobalSpeakersApi = { getSpeakers, updateStatus }
