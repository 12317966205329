import React, { FC } from 'react'
import { Redirect, Route, RouterProps } from 'react-router'

import { useAuth } from '@hooks'

const PrivateRoute: FC<RouterProps> = ({ children, ...rest }) => {
  const { isAuthenticated } = useAuth()

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

export { PrivateRoute }
