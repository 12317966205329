import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  paper: {
    padding: 40,
    marginBottom: 25,
  },
  title: {
    marginBottom: 20,
  },
  button: {
    width: 207,
    height: 51,
    textDecoration: 'none',
    marginBottom: 25,
  },
})

export { useStyles }
