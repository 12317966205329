import FormData from 'form-data'

import { api } from '@utils'

import { UpdateSpeakerPayload, GetSpeakerResponse } from '../types'

const getSpeaker = async (_: string, id: number) => {
  const response = await api.get<GetSpeakerResponse>(`/speakers/${id!}`)
  return response.data.speaker
}

const updateSpeaker = async ({ id, profilePicture, ...speaker }: UpdateSpeakerPayload) => {
  const formData = new FormData()

  if (profilePicture) formData.append('image', profilePicture)
  formData.append('speaker', JSON.stringify(speaker))

  try {
    const response = await api.put<UpdateSpeakerPayload>(`/speakers/${id}`, formData)
    return response.data
  } catch (err) {
    throw err.response.data.error
  }
}

export const UpdateSpeakersApi = { getSpeaker, updateSpeaker }
