import { api } from '@utils'
import { FormMedica } from '@types'

interface GetFormMedicaPayload {
  page?: number
  limit?: number
  name?: string
  isActive?: true
  date: string | null
}

interface GetFormMedicaResponse {
  count: number
  formMedica: FormMedica[]
}

const getFormMedica = async (
  _: string,
  { page = 0, limit = 10, date }: GetFormMedicaPayload,
): Promise<GetFormMedicaResponse> => {
  const params = {
    offset: page * limit,
    limit,
    date,
  }
  const response = await api.get<GetFormMedicaResponse>('/form-medica', { params })

  return response.data
}

export const GlobalFormMedicaApi = { getFormMedica }
