import React, { FC } from 'react'
import { Route, Switch, RouteComponentProps } from 'react-router-dom'

import { AddUser } from './AddUser'
import { UserDetails } from './UserDetails'
import { Users } from './Users'

export const UsersRouter: FC<RouteComponentProps> = ({ match }) => {
  return (
    <Switch>
      <Route exact path={match.path} component={Users} />
      <Route exatc path={`${match.path}/add`} component={AddUser} />
      <Route exatc path={`${match.path}/edit/:id`} component={UserDetails} />
    </Switch>
  )
}
