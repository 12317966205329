import FormData from 'form-data'

import { api } from '@utils'

import { UpdateStatusPayload, UpdateStatusResponse } from '../types'

const updateStatus = async ({
  id,
  newStatus,
}: UpdateStatusPayload): Promise<UpdateStatusResponse> => {
  const formData = new FormData()
  formData.append('homeCarouselItem', JSON.stringify({ isActive: newStatus }))

  try {
    const response = await api.put<UpdateStatusResponse>(`/home-carousel-items/${id}`, formData)
    return response.data
  } catch (err) {
    throw err.response.data.error
  }
}

export const HomeCarouselApi = { updateStatus }
