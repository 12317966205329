import { api } from '@utils'
import { Admin, CacheUser, LoginResponse } from '@types'

export type LoginPayload = Omit<Admin, 'id'>

const login = async (payload: LoginPayload) => {
  try {
    const response = await api.post<LoginResponse>('/auth/v1/login/admin', payload)
    return response.data
  } catch (err) {
    throw err.response.data.error
  }
}

const loginWithToken = async () => {
  try {
    const response = await api.post<CacheUser>('/auth/v1/login/token/admin')
    return response.data
  } catch (err) {
    throw err.response.data.error
  }
}

export const GlobalUsersApi = { login, loginWithToken }
