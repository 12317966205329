import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  fileDropzone: {
    position: 'relative',
    overflow: 'hidden',
    display: 'inline-block',
    maxWidth: '100%',
    marginTop: 10,
    borderRadius: 5,
  },
  dropArea: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    backgroundColor: '#EEE',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    textAlign: 'center',
    padding: 20,
    border: '3px dashed rgba(0,0,0,0.07)',
  },
  image: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 0,
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },
})

export { useStyles }
