import React, { FC, useState } from 'react'
import MaterialDrawer from '@material-ui/core/Drawer'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
} from '@material-ui/core'
import {
  Menu,
  ExitToApp,
  RecordVoiceOver,
  OndemandVideo,
  ViewCarousel,
  Label,
  LocalHospital,
  Person,
  ChevronLeft,
} from '@material-ui/icons'

import { useAuth } from '@hooks'

import { useStyles } from './styles'

const items = [
  [
    {
      text: 'Carrusel Home',
      Icon: ViewCarousel,
      link: '/home',
    },
  ],
  [
    {
      text: 'Users',
      Icon: Person,
      link: '/users',
    },
    {
      text: 'FormMedica',
      Icon: LocalHospital,
      link: '/form-medica',
    },
    {
      text: 'Tags',
      Icon: Label,
      link: '/tags',
    },
    {
      text: 'Webinars',
      Icon: OndemandVideo,
      link: '/webinars',
    },
    {
      text: 'Speakers',
      Icon: RecordVoiceOver,
      link: '/speakers',
    },
  ],
]

export const Drawer: FC = () => {
  const classes = useStyles()
  const history = useHistory()

  const { logout } = useAuth()

  const [open, setOpen] = useState(false)

  return (
    <>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setOpen(true)}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <Menu />
          </IconButton>
          <Typography variant="h6" noWrap>
            Espacio Oncología
          </Typography>
        </Toolbar>
      </AppBar>
      <MaterialDrawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        anchor="left"
      >
        <div className={classes.toolbar}>
          <IconButton onClick={() => setOpen(false)}>{open && <ChevronLeft />}</IconButton>
        </div>
        <Divider />
        {items.map(itemList => (
          <div key={itemList.toString()}>
            <List>
              {itemList.map(({ text, Icon, link }) => (
                <ListItem key={text} onClick={() => history.push(link)} button>
                  <ListItemIcon>
                    <Icon />
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              ))}
            </List>
            <Divider />
          </div>
        ))}
        <ListItem onClick={logout} button>
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary="Cerrar sesión" />
        </ListItem>
      </MaterialDrawer>
    </>
  )
}
