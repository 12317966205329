import React, { FC, useMemo, useState } from 'react'
import { Box, Tooltip } from '@material-ui/core'
import { useQuery, useMutation, queryCache } from 'react-query'
import IconButton from '@material-ui/core/IconButton'
import VisibilityIcon from '@material-ui/icons/Visibility'
import GetApp from '@material-ui/icons/GetApp'
import { KeyboardDatePicker } from '@material-ui/pickers'
import moment from 'moment'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'

import { QUERY_KEYS, GlobalFormMedicaApi } from '@api'
import { FormMedica } from '@types'
import { STRINGS } from '@constants'
import { useCustomSnackbar } from '@hooks'

import { MuiTable, Header } from '../components'
import { FormMedicaApi, GetFormsListFile } from './api'
import { FormMedicaModal } from './components'
import { useStyles } from './styles'

const columns = [
  { label: 'Fecha', name: 'createdAt' },
  { label: 'Usuario', name: 'email' },
  { label: 'Patología', name: 'therapeuticArea' },
  { label: 'Nombre', name: 'name' },
  { label: 'Apellido', name: 'lastName' },
  { label: 'Especialidad', name: 'specialty' },
  { label: 'Asunto', name: 'subject' },
  { label: 'Descripción', name: 'request' },
  { label: 'Acciones', name: 'actions' },
]

export const FormMedicas: FC = () => {
  const classes = useStyles()
  const snackbar = useCustomSnackbar()

  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [isInfoFormModalOpen, setIsInfoFormModalOpen] = useState(false)
  const [FormSelected, setFormSelected] = useState<FormMedica | null>(null)
  const [selectedDate, setSelectedDate] = useState<MaterialUiPickersDate | null>(null)

  const handleDateChange = (date: MaterialUiPickersDate) => {
    setSelectedDate(date)
  }

  const { data: forms } = useQuery(
    [QUERY_KEYS.FORMS, { page, limit: rowsPerPage, date: selectedDate?.toISOString() }],
    GlobalFormMedicaApi.getFormMedica,
  )

  const [downloadList] = useMutation(FormMedicaApi.downloadList, {
    onSuccess: (data: GetFormsListFile) => {
      queryCache.invalidateQueries(QUERY_KEYS.USER)
      const byteArray = new Uint8Array(data.file.data)
      const a = window.document.createElement('a')

      a.href = window.URL.createObjectURL(
        new Blob([byteArray], {
          type: 'text/csv;charset=utf-8',
        }),
      )
      a.download = 'listado_de_info_medica'

      document.body.appendChild(a)
      a.click()

      document.body.removeChild(a)
      snackbar.showSuccess('¡Lista descargada con éxito!')
    },
    onError: () => {
      snackbar.showError('No se encontraron resultados...')
    },
  })

  const formsData = useMemo(
    () =>
      forms?.formMedica.map(form => ({
        ...form,
        createdAt: moment(form?.created_at).format(STRINGS.DATE_FORMAT),
        email: form?.user.email,
        lastName: form?.user.lastName,
        name: form?.user.name,
        specialty: form?.user.specialty,
        therapeuticArea: form?.therapeuticAreas.name,
        actions: (
          <div key={form?.created_at}>
            <Tooltip title="Ver" placement="top">
              <IconButton
                color="primary"
                aria-label=""
                component="span"
                onClick={() => {
                  setFormSelected(form)
                  setIsInfoFormModalOpen(true)
                }}
              >
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
          </div>
        ),
      })),
    [forms],
  )

  return (
    <>
      <Header title="Información Médica">
        <Tooltip title="Descargar listado" placement="top">
          <IconButton
            color="primary"
            aria-label="Descargar listado"
            component="span"
            onClick={() => downloadList({ date: selectedDate?.toISOString() })}
          >
            <GetApp />
          </IconButton>
        </Tooltip>
      </Header>
      <Box className={classes.box}>
        <KeyboardDatePicker
          margin="normal"
          className={classes.date}
          id="date-picker-dialog"
          label="Filtrar por fecha"
          format="DD/MM/yyyy"
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </Box>

      <FormMedicaModal
        isOpen={isInfoFormModalOpen}
        handleClose={() => setIsInfoFormModalOpen(false)}
        form={FormSelected}
      />
      <MuiTable
        title=""
        columns={columns}
        data={formsData ?? []}
        options={{
          page,
          count: forms?.count ?? 0,
          serverSide: true,
          onChangePage: setPage,
          onChangeRowsPerPage: numberOfRows => {
            setPage(0)
            setRowsPerPage(numberOfRows)
          },
          sort: false,
        }}
      />
    </>
  )
}
