import React, { FC } from 'react'
import { Paper } from '@material-ui/core'
import { useMutation, queryCache } from 'react-query'
import { useHistory } from 'react-router'

import { QUERY_KEYS } from '@api'
import { useCustomSnackbar } from '@hooks'
import { ErrorResponse } from '@types'

import { useStyles } from './styles'
import 'react-quill/dist/quill.snow.css'
import { UserForm } from '../components'
import { Header } from '../../components'
import { AddUsersApi } from './api'

export const AddUser: FC = () => {
  const classes = useStyles()
  const snackbar = useCustomSnackbar()
  const { push } = useHistory()

  const [add, { isLoading }] = useMutation(AddUsersApi.addUser, {
    onSuccess: () => {
      queryCache.invalidateQueries(QUERY_KEYS.USER)
      snackbar.showSuccess('¡Guardado con éxito!')
      push(`/users`)
    },
    onError: (error: ErrorResponse) => {
      snackbar.showError(error.message)
    },
  })

  return (
    <>
      <Header title="Agregar Usuario" />

      <Paper elevation={1} className={classes.paper}>
        <UserForm handleSubmit={add} isLoading={isLoading} />
      </Paper>
    </>
  )
}
