import FormData from 'form-data'

import { api } from '@utils'
import { Registrant } from '@types'

import { UpdateWebinarPayload, GetWebinarResponse } from '../types'

type GetRegistrantsResponse = {
  registrants: Registrant[]
}

const getWebinar = async (_: string, id: number) => {
  const response = await api.get<GetWebinarResponse>(`/webinars/${id}`)
  return response.data.webinar
}

const updateWebinar = async ({ id, payload: { image, ...payload } }: UpdateWebinarPayload) => {
  const formData = new FormData()
  formData.append('webinar', JSON.stringify(payload))
  try {
    const response = await api.put(`/webinars/${id}`, formData)
    return response.data.webinar
  } catch (err) {
    throw err.response.data.error
  }
}

const exportRegistrantsCsvUrl = (id: number) =>
  `${process.env.REACT_APP_API_URL}/webinars/${id}/reports/registrants`

export const UpdateWebinarApi = {
  getWebinar,
  updateWebinar,
  exportRegistrantsCsvUrl,
}
