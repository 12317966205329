import React, { FC, useState } from 'react'
import {
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core'
import { useQuery } from 'react-query'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import ReactQuill from 'react-quill'
import { DateTimePicker } from '@material-ui/pickers'
import moment from 'moment'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { Alert } from '@material-ui/lab'
import { useHistory } from 'react-router'
import { STRINGS } from '@constants'
import { useGlobalStyles } from '@styles'
import { QUERY_KEYS, GlobalSpeakersApi, GlobalTagsApi, GlobalTherapeuticAreasApi } from '@api'
import { Webinar, WebinarStatus } from '@types'

import { useStyles } from './styles'
import { WebinarFormType, WebinarFormTypePayload } from '../../types'

const validationSchema = yup.object().shape({
  title: yup.string().max(150, 'Máximo 150 caracteres').required(),
  date: yup.date().required(),
  hours: yup.number(),
  minutes: yup.number(),
  description: yup.string().required(),
  videoId: yup.string(),
  therapeuticAreaId: yup.number().required(),
  image: yup.mixed(),
  zoomId: yup.number(),
  password: yup.string(),
  status: yup.string(),
  isSalient: yup.boolean(),
  isActive: yup.boolean(),
  tags: yup.array().required(),
  speakers: yup.array().required(),
})

interface Props {
  webinar?: Webinar
  handleSubmit: (webinar: WebinarFormTypePayload) => void
  isLoading?: boolean
}

export const WebinarForm: FC<Props> = ({ webinar, handleSubmit: submit, isLoading }) => {
  const globalClasses = useGlobalStyles()
  const classes = useStyles()
  const { push } = useHistory()
  const [speakerFilter, setSpeakersFilter] = useState('')
  const [tagsFilter, setTagsFilter] = useState('')

  const defaultValues = {
    date: webinar?.date ? moment(webinar.date) : null,
    hours: webinar?.duration ? Math.floor(webinar.duration / 60) : 0,
    minutes: webinar?.duration ? webinar.duration % 60 : 0,
    title: webinar?.title ?? undefined,
    description: webinar?.description ?? '',
    videoId: webinar?.videoId ?? '',
    image: null,
    imageUrl: webinar?.imageUrl,
    therapeuticAreaId: webinar?.therapeuticArea?.id || null,
    zoomId: webinar?.zoomId,
    password: webinar?.password ?? '',
    status: webinar?.status ?? WebinarStatus.draft,
    isSalient: webinar?.isSalient ?? false,
    isActive: webinar?.isActive ?? false,
    tags: webinar?.tags ?? [],
    speakers: webinar?.speakers ?? [],
  }

  const { data: speakersData } = useQuery(
    [
      QUERY_KEYS.SPEAKERS,
      { name: speakerFilter, lastName: speakerFilter, specialty: speakerFilter, isActive: true },
    ],
    GlobalSpeakersApi.getSpeakers,
  )
  const { data: tagsData } = useQuery(
    [QUERY_KEYS.TAGS, { page: 0, name: tagsFilter, isActive: true }],
    GlobalTagsApi.getTags,
  )

  const { data: therapeuticAreas } = useQuery(
    QUERY_KEYS.THERAPEUTIC_AREAS,
    GlobalTherapeuticAreasApi.getTherapeuticAreas,
  )

  const { control, setValue, handleSubmit, watch, errors } = useForm<WebinarFormType>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })

  const webinarStatus = watch('status')

  const onSubmit: SubmitHandler<WebinarFormType> = data => {
    const { tags, speakers, hours, minutes, ...webinarPayload } = data

    submit({
      duration: hours * 60 + minutes,
      tags: tags.map(tag => tag.id),
      speakers: speakers.map(speaker => speaker.id),
      ...webinarPayload,
    })
  }

  return (
    <form className={globalClasses.form} onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h5" className={classes.title}>
        Datos del webinar
      </Typography>
      <Grid container spacing={10}>
        <Grid item lg={6} md={12}>
          <Controller
            name="title"
            control={control}
            as={
              <TextField
                label="Título*"
                placeholder="Ingresar título"
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                autoComplete="off"
                size="small"
              />
            }
          />
          {errors.title && <Alert severity="error">Este campo es obligatorio{/*errors.title.message*/}</Alert>}

          <div className={classes.formGroup}>
            <Controller
              name="date"
              control={control}
              render={props => (
                <DateTimePicker
                  value={props.value}
                  onChange={props.onChange}
                  initialFocusedDate={new Date()}
                  format={STRINGS.DATE_FORMAT}
                  InputLabelProps={{ shrink: true }}
                  inputVariant="outlined"
                  // TODO disabled={isLoading}
                  label="Fecha de inicio*"
                  placeholder="Ingresar fecha de inicio"
                  autoOk
                  size="small"
                />
              )}
            />
            <Typography className="label">Duración</Typography>
            <FormControl
              size="small"
              variant="outlined"
              margin="normal"
              className={classes.formControl}
            >
              <InputLabel id="hours-label">Horas</InputLabel>
              <Controller
                name="hours"
                control={control}
                as={
                  <Select
                    labelId="hours-label"
                    id="hours"
                    label="Hora*"
                    className={classes.numericControl}
                  >
                    {Array.from(Array(24).keys()).map(i => (
                      <MenuItem key={i} value={i}>
                        {i}
                      </MenuItem>
                    ))}
                  </Select>
                }
              />
            </FormControl>
            <FormControl
              variant="outlined"
              size="small"
              margin="normal"
              className={classes.formControl}
            >
              <InputLabel id="minutes-label">Minutos</InputLabel>
              <Controller
                name="minutes"
                control={control}
                defaultValue="0"
                as={
                  <Select
                    labelId="minutes-label"
                    id="minutes"
                    label="Minutos*"
                    className={classes.numericControl}
                  >
                    <MenuItem value={0}>0</MenuItem>
                    <MenuItem value={15}>15</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                    <MenuItem value={45}>45</MenuItem>
                  </Select>
                }
              />
            </FormControl>
          </div>
          {errors.date && <Alert severity="error">Este campo es obligatorio</Alert>}
          {errors.hours && <Alert severity="error">Este campo es obligatorio</Alert>}
          {errors.minutes && <Alert severity="error">Este campo es obligatorio</Alert>}

          <FormControl variant="outlined" size="small" margin="normal" style={{ width: '100%' }}>
            <InputLabel id="therapeutic-area-label">Área terapéutica*</InputLabel>
            <Controller
              name="therapeuticAreaId"
              control={control}
              defaultValue=""
              as={
                <Select
                  labelId="therapeutic-area-label"
                  id="therapeutic-area"
                  label="Área terapéutica*"
                >
                  <MenuItem>Seleccionar área terapéutica</MenuItem>
                  {therapeuticAreas?.map(ta => {
                    return (
                      <MenuItem key={ta.name} value={ta.id ?? ''}>
                        {ta.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              }
            />
          </FormControl>
          {errors.therapeuticAreaId && <Alert severity="error">Este campo es obligatorio</Alert>}

          <Controller
            name="speakers"
            control={control}
            render={props => (
              <Autocomplete
                multiple
                id="checkboxes-speakers"
                options={speakersData?.speakers ?? []}
                getOptionSelected={(speaker, value) => speaker.id === value.id}
                disableCloseOnSelect
                getOptionLabel={option => `${option.name} ${option.lastName}`}
                onChange={(event, value) => props.onChange(value)}
                size="small"
                renderOption={(option, { selected }) => (
                  <>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name} {option.lastName}
                  </>
                )}
                value={props.value}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Speakers*"
                    placeholder="Seleccionar Speakers"
                    margin="normal"
                    onChange={ev => {
                      if (ev.target.value !== '' || ev.target.value !== null) {
                        setSpeakersFilter(ev.target.value)
                      }
                    }}
                  />
                )}
              />
            )}
          />
          {errors.speakers && <Alert severity="error">Este campo es obligatorio</Alert>}

          <Controller
            name="tags"
            control={control}
            render={props => (
              <Autocomplete
                multiple
                id="checkboxes-tags"
                options={tagsData?.tags ?? []}
                disableCloseOnSelect
                getOptionSelected={(tag, value) => tag.id === value.id}
                getOptionLabel={option => option.name}
                onChange={(event, value) => props.onChange(value)}
                size="small"
                renderOption={(option, { selected }) => (
                  <>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </>
                )}
                value={props.value}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Tags*"
                    placeholder="Seleccionar Tags"
                    margin="normal"
                    onChange={ev => {
                      if (ev.target.value !== '' || ev.target.value !== null) {
                        setTagsFilter(ev.target.value)
                      }
                    }}
                  />
                )}
              />
            )}
          />
          {errors.tags && <Alert severity="error">Este campo es obligatorio</Alert>}

          <Controller
            name="videoId"
            control={control}
            as={
              <TextField
                label="Id del Video"
                placeholder="Ingresar el Id del video"
                fullWidth
                margin="normal"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                autoComplete="off"
                size="small"
              />
            }
          />
        </Grid>

        <Grid item lg={6} md={12}>
          {/* TODO
          <Typography variant="h5" className={classes.title}>
            Image de perfil
          </Typography>

          <Controller
            name="image"
            control={control}
            render={props => (
              <FileDropzone
                onDropFile={props.onChange}
                defaultImage={webinar?.imageUrl}
                image={props.value}
                width="100%"
                height={300}
              />
            )}
            />
            */}

          <Controller
            name="description"
            control={control}
            render={props => (
              <>
                <Typography style={{ margin: '10px 0' }}>Descripción*</Typography>
                <ReactQuill
                  value={props.value}
                  onChange={props.onChange}
                  className={classes.editor}
                />
              </>
            )}
          />
          {errors.description && <Alert severity="error">Este campo es obligatorio</Alert>}
        </Grid>
        <Grid item lg={6} md={12}>
          <Typography variant="h5" className={classes.title}>
            Estados
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary="Destacado" />
              <ListItemSecondaryAction>
                <Controller
                  name="isSalient"
                  control={control}
                  render={props => (
                    <Switch
                      edge="end"
                      onChange={() => props.onChange(!props.value)}
                      checked={props.value}
                      inputProps={{ 'aria-labelledby': 'switch-list-label-bluetooth' }}
                    />
                  )}
                />
              </ListItemSecondaryAction>
            </ListItem>

            <ListItem>
              <ListItemText primary="Publicado" />
              <ListItemSecondaryAction>
                <Controller
                  name="isActive"
                  control={control}
                  render={props => (
                    <Switch
                      disabled={
                        webinarStatus !== WebinarStatus.completed &&
                        webinarStatus !== WebinarStatus.finalized
                      }
                      edge="end"
                      onChange={() => props.onChange(!props.value)}
                      checked={props.value}
                      inputProps={{ 'aria-labelledby': 'switch-list-label-bluetooth' }}
                    />
                  )}
                />
              </ListItemSecondaryAction>
            </ListItem>

            <ListItem>
              <ListItemText primary="Estado:" />
              <ListItemSecondaryAction>
                <FormControl
                  size="small"
                  variant="outlined"
                  margin="normal"
                  className={classes.formControl}
                  style={{ marginRight: 0 }}
                >
                  <InputLabel id="status-label">Estado</InputLabel>
                  <Controller
                    name="status"
                    control={control}
                    render={() => (
                      <Select
                        onChange={(
                          event: React.ChangeEvent<{ value: WebinarStatus | unknown }>,
                        ) => {
                          if (
                            event.target.value !== WebinarStatus.completed &&
                            event.target.value !== WebinarStatus.finalized
                          ) {
                            setValue('isActive', false)
                          }
                          setValue('status', event.target.value as WebinarStatus)
                        }}
                        labelId="status-label"
                        id="Estado"
                        label="Estado"
                        defaultValue={webinar?.status}
                        style={{ width: 300 }}
                        disabled={webinar?.status === 'started'}
                      >
                        <MenuItem value="discarded">Descartado</MenuItem>
                        <MenuItem value="pending">Pendiente</MenuItem>
                        <MenuItem value="draft" selected>
                          Borrador
                        </MenuItem>
                        <MenuItem value="completed">Completado</MenuItem>
                        {webinar?.status === 'started' && (
                          <MenuItem value="started">En curso</MenuItem>
                        )}
                        <MenuItem value="finalized">Finalizado</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </Grid>

        {webinar?.zoomId && (
          <Grid item lg={6} md={12}>
            <Typography variant="h5" className={classes.title}>
              Datos de Zoom
            </Typography>
            <List>
              {defaultValues.zoomId && (
                <ListItem>
                  <ListItemText primary="Zoom ID:" />
                  <ListItemSecondaryAction>{defaultValues.zoomId}</ListItemSecondaryAction>
                </ListItem>
              )}

              {webinar?.joinUrl && (
                <ListItem>
                  <ListItemText primary="Link" />
                  <ListItemSecondaryAction>
                    <a href={webinar?.joinUrl} rel="external" className={classes.zoomLink}>
                      {webinar?.joinUrl}
                    </a>
                    <IconButton
                      color="primary"
                      aria-label="Copiar link"
                      onClick={() => {
                        navigator.clipboard.writeText(defaultValues?.zoomId?.toString() ?? '')
                      }}
                    >
                      <FileCopyIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              )}

              {/* TODO <ListItem>
              <ListItemText primary="Password:" />
              <ListItemSecondaryAction>
                <Controller
                  name="password"
                  control={control}
                  as={
                    <TextField
                      label="Password"
                      placeholder="Password de la meet"
                      fullWidth
                      margin="normal"
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      autoComplete="off"
                      size="small"
                    />
                  }
                />
              </ListItemSecondaryAction>
            </ListItem> */}
            </List>
          </Grid>
        )}
      </Grid>
      <Divider className={classes.divider} />
      <div className={classes.actions}>
        <Button
          onClick={() => push('/webinars')}
          className={classes.actionsButton}
          variant="contained"
          color="secondary"
        >
          Cancelar
        </Button>
        <Button type="submit" variant="contained" color="primary" className={classes.actionsButton}>
          {webinar?.id ? 'Actualizar' : 'Guardar'}
        </Button>
        {isLoading && <CircularProgress disableShrink size={26} />}
      </div>
    </form>
  )
}
