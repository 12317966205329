import React, { FC } from 'react'
import { Paper } from '@material-ui/core'
import { useMutation, queryCache } from 'react-query'
import { useHistory } from 'react-router'

import { QUERY_KEYS } from '@api'
import { useCustomSnackbar } from '@hooks'
import { ErrorResponse } from '@types'

import { SpeakerForm } from '../components'
import { AddSpeakersApi } from './api'
import { useStyles } from './styles'
import { Header } from '../../components'

import 'react-quill/dist/quill.snow.css'

export const AddSpeaker: FC = () => {
  const classes = useStyles()
  const snackbar = useCustomSnackbar()
  const { push } = useHistory()

  const [add, { isLoading }] = useMutation(AddSpeakersApi.addSpeaker, {
    onSuccess: ({ speaker }) => {
      queryCache.invalidateQueries(QUERY_KEYS.SPEAKERS)
      snackbar.showSuccess('¡Guardado con éxito!')
      push(`edit/${speaker.id}`)
    },
    onError: (error: ErrorResponse) => {
      snackbar.showError(error.message)
    },
  })

  return (
    <>
      <Header title="Agregar Speaker" />

      <Paper elevation={1} className={classes.paper}>
        <SpeakerForm handleSubmit={add} isLoading={isLoading} />
      </Paper>
    </>
  )
}
