import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  name: {
    fontWeight: 'bold',
    fontSize: 22,
  },
  email: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: 10,
    },
  },
})

export { useStyles }
