import { useMemo } from 'react'
import { useSnackbar } from 'material-ui-snackbar-provider'

const useCustomSnackbar = () => {
  const snackbar = useSnackbar()

  return useMemo(() => {
    const showMessage = (type: string) => (
      message: string,
      action?: string,
      handleAction?: () => void,
      customParameters?: {},
    ) =>
      snackbar.showMessage(message, action, handleAction, {
        ...customParameters,
        type,
      })
    return {
      ...snackbar,
      showMessage: showMessage('info'),
      showInfo: showMessage('info'),
      showWarning: showMessage('warning'),
      showError: showMessage('error'),
      showSuccess: showMessage('success'),
    }
  }, [snackbar])
}

export { useCustomSnackbar }
