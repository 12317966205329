import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  search: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    height: 42,
    marginLeft: 'auto',
    borderRadius: 20,
  },
  searchIcon: {
    color: 'white',
    padding: '0 10px',
  },
  inputRoot: {
    color: 'red',
  },
  inputInput: {
    color: 'white',
  },
}))

export { useStyles }
