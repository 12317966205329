import React, { FC } from 'react'
import SearchIcon from '@material-ui/icons/Search'
import { InputBase } from '@material-ui/core'

import { useStyles } from './styles'

interface Props {
  handleTextChange: (inputText: string) => void
}

export const Search: FC<Props> = ({ handleTextChange }) => {
  const classes = useStyles()

  return (
    <div className={classes.search}>
      <div className={classes.inputWrapper}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          placeholder="FILTRAR USUARIOS"
          onChange={e => handleTextChange(e.target.value)}
          classes={{
            input: classes.inputInput,
          }}
          inputProps={{ 'aria-label': 'buscar' }}
        />
      </div>
    </div>
  )
}
