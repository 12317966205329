import React from 'react'
import { Route } from 'react-router-dom'

import { Layout } from '@components'

import { HomeCarouselRouter } from './HomeCarouselRouter'
import { Tags } from './Tags'
import { FormMedicas } from './FormMedicas'
import { WebinarsRouter } from './WebinarsRouter'
import { SpeakersRouter } from './SpeakersRouter'
import { UsersRouter } from './UsersRouter'

const Main = () => {
  return (
    <Layout>
      <Route path="/home" component={HomeCarouselRouter} />
      <Route path="/tags" component={Tags} />
      <Route path="/speakers" component={SpeakersRouter} />
      <Route path="/webinars" component={WebinarsRouter} />
      <Route path="/users" component={UsersRouter} />
      <Route path="/form-medica" component={FormMedicas} />
    </Layout>
  )
}

export default Main
