import React, { FC } from 'react'
import { Modal, Fade, Container, Card, CardHeader, CardContent } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import EmailIcon from '@material-ui/icons/Email'

import { User } from '@types'
import { useGlobalStyles } from '@styles'

import { useStyles } from './styles'

interface Props {
  isOpen: boolean
  handleClose: () => void
  user: User | null
}

export const UserModal: FC<Props> = ({ isOpen, handleClose, user }) => {
  const globalClasses = useGlobalStyles()
  const classes = useStyles()

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={globalClasses.modal}
    >
      <Fade in={isOpen} timeout={500}>
        <Container className={globalClasses.modalContainer}>
          <Card className={globalClasses.modalCard}>
            <CloseIcon className={globalClasses.modalCloseIcon} onClick={handleClose} />
            <CardHeader
              title={
                <span
                  className={classes.name}
                >{`${user?.title} ${user?.name} ${user?.lastName}`}</span>
              }
              subheader={` ${user?.country}`}
            />
            <CardContent>
              <div className={classes.email}>
                <EmailIcon /> {user?.email}
              </div>
            </CardContent>
          </Card>
        </Container>
      </Fade>
    </Modal>
  )
}
