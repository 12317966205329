import FormData from 'form-data'

import { api } from '@utils'

import { AddSpeakerPayload } from '../types'
import { AddSpeakerResponse } from './types'

const addSpeaker = async ({
  profilePicture,
  ...speaker
}: AddSpeakerPayload): Promise<AddSpeakerResponse> => {
  const formData = new FormData()

  if (profilePicture) formData.append('image', profilePicture)
  formData.append('speaker', JSON.stringify(speaker))

  try {
    const response = await api.post<AddSpeakerResponse>('/speakers', formData)
    return response.data
  } catch (err) {
    throw err.response.data.error
  }
}

export const AddSpeakersApi = { addSpeaker }
