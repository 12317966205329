import FormData from 'form-data'

import { api } from '@utils'

import { AddCarouselPayload } from '../types'

const addCarousel = async ({ image, ...carousel }: AddCarouselPayload) => {
  const formData = new FormData()

  formData.append('image', image)
  formData.append('homeCarouselItem', JSON.stringify(carousel))

  try {
    const response = await api.post('/home-carousel-items', formData)
    return response.data
  } catch (err) {
    throw err.response.data.error
  }
}

export const HomeCarouselApi = { addCarousel }
