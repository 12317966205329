import React, { FC, useState, useMemo, useEffect } from 'react'
import { Tooltip, IconButton, Switch, LinearProgress } from '@material-ui/core'
import { queryCache, useMutation, useQuery } from 'react-query'
import { useHistory, useLocation } from 'react-router'
import moment from 'moment'
import { Star, Edit, Delete, Add, GetApp } from '@material-ui/icons'

import { QUERY_KEYS } from '@api'
import { ErrorResponse, Webinar, WebinarStatus } from '@types'
import { STRINGS } from '@constants'
import { useCSVReport, useCustomSnackbar } from '@hooks'

import { MuiTable, Header } from '../../components'
import { WebinarsApi } from './api'
import { NavFilter, RemoveWebinarModal } from '../components'
import { UpdateWebinarApi } from '../WebinarDetails/api'

const columns = [
  { label: 'id', name: 'id' },
  { label: 'Título', name: 'title' },
  { label: 'Fecha del Webinar', name: 'date' },
  { label: 'Área terapéutica', name: 'therapeuticArea' },
  { label: 'Estado', name: 'status' },
  { label: 'Destacado', name: 'isSalient' },
  { label: 'Publicado', name: 'published' },
  { label: 'Acciones', name: 'actions' },
]

export const Webinars: FC = () => {
  const history = useHistory()
  const snackBar = useCustomSnackbar()

  const [isRemoveWebinarModalOpen, setIsRemoveWebinarModalOpen] = useState(false)
  const [webinarSelected, setWebinarSelected] = useState<Webinar | null>(null)
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [search, setSearch] = useState('')
  const [therapeuticAreaId, setTherapeuticAreaId] = useState<string | null>(null)
  const [time, setTime] = useState<string | null>()
  const { search: reactRouterSearch } = useLocation()
  const params = useMemo(() => new URLSearchParams(reactRouterSearch), [reactRouterSearch])

  const { handleCSVReport } = useCSVReport()

  const renderStatus = (status: string) => {
    switch (status) {
      case 'draft':
        return 'Borrador'
      case 'pending':
        return 'Pendiente'
      case 'completed':
        return 'Completado'
      case 'discarded':
        return 'Descartado'
      case 'started':
        return 'En curso'
      case 'finalized':
        return 'Finalizado'
      default:
        return null
    }
  }

  const { data } = useQuery(
    [
      QUERY_KEYS.WEBINARS,
      {
        page,
        limit: rowsPerPage,
        title: search,
        therapeuticAreaId,
        pastWebinars: time === 'past',
        nextWebinars: time === 'future',
      },
    ],
    WebinarsApi.getWebinars,
  )

  useEffect(() => {
    setTherapeuticAreaId(params.get('therapeutic-area'))
    setTime(params.get('time'))
    setPage(0)
  }, [params, search])

  const [update, { isLoading }] = useMutation(WebinarsApi.update, {
    onSuccess: async () => {
      await queryCache.invalidateQueries(QUERY_KEYS.WEBINARS)
      snackBar.showSuccess('¡El ítem se actualizó con éxito!')
    },
    onError: (error: ErrorResponse) => {
      snackBar.showError(error.message)
    },
  })

  const webinars = useMemo(
    () =>
      data?.webinars.map(webinar => ({
        ...webinar,
        duration: webinar.duration ?? '',
        therapeuticArea: webinar.therapeuticArea?.name ?? '',
        date: moment(webinar.date).format(STRINGS.DATE_FORMAT),
        published:
          webinar.status !== WebinarStatus.completed ? (
            <Tooltip title="Para activar el webinar, deben estar todos los datos completos">
              <Switch
                id={`check${webinar.id}`}
                checked={webinar.isActive}
                name={`check${webinar.id}`}
                value={webinar.id}
              />
            </Tooltip>
          ) : (
            <Tooltip title={`Click para ${webinar.isActive ? 'Despublicar' : 'Publicar'}`}>
              <Switch
                id={`check${webinar.id}`}
                checked={webinar.isActive}
                onChange={() =>
                  update({
                    id: webinar.id,
                    isActive: !webinar.isActive,
                    isSalient: webinar.isSalient,
                  })
                }
                name={`check${webinar.id}`}
                value={webinar.id}
              />
            </Tooltip>
          ),
        status: renderStatus(webinar.status),
        isSalient: (
          <Tooltip title={`Click para ${webinar.isSalient ? 'quitar de destacados' : 'Destacar'}`}>
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              style={{ color: webinar.isSalient ? 'goldenRod' : 'silver' }}
              onClick={() =>
                update({
                  id: webinar.id,
                  isActive: webinar.isActive,
                  isSalient: !webinar.isSalient,
                })
              }
            >
              <Star />
            </IconButton>
          </Tooltip>
        ),
        actions: (
          <div key={webinar.title}>
            <Tooltip title="Editar webinar" placement="top">
              <IconButton
                color="primary"
                aria-label="Editar webinar"
                component="span"
                onClick={() => history.push(`/webinars/edit/${webinar.id}`)}
              >
                <Edit />
              </IconButton>
            </Tooltip>
            {webinar.status !== WebinarStatus.discarded && (
              <Tooltip title="Eliminar webinar" placement="top">
                <IconButton
                  color="secondary"
                  aria-label="Actualizar webinar"
                  component="span"
                  onClick={() => {
                    setWebinarSelected(webinar)
                    setIsRemoveWebinarModalOpen(true)
                  }}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            )}
            {webinar.status === WebinarStatus.finalized && (
              <Tooltip title="Descargar lista de participantes" placement="top">
                <IconButton
                  color="primary"
                  aria-label="Actualizar webinar"
                  component="span"
                  onClick={() =>
                    handleCSVReport(UpdateWebinarApi.exportRegistrantsCsvUrl(webinar.id))
                  }
                >
                  <GetApp />
                </IconButton>
              </Tooltip>
            )}
          </div>
        ),
      })),
    [data, history, update, handleCSVReport],
  )
  const onHandleChangeSearch = (text: string) => {
    setPage(0)
    setSearch(text)
  }

  return (
    <>
      <Header title="Webinars">
        <Tooltip title="Agregar nuevo webinar" placement="top">
          <IconButton
            color="primary"
            aria-label="agregar nuevo webinar"
            component="span"
            onClick={() => history.push('/webinars/add')}
          >
            <Add />
          </IconButton>
        </Tooltip>
      </Header>
      <NavFilter handleTextChange={onHandleChangeSearch} />
      <RemoveWebinarModal
        isOpen={isRemoveWebinarModalOpen}
        handleClose={() => setIsRemoveWebinarModalOpen(false)}
        id={webinarSelected?.id!}
      />
      {isLoading && <LinearProgress />}
      <MuiTable
        title=""
        columns={columns}
        data={webinars ?? []}
        options={{
          page,
          count: data?.count ?? 0,
          serverSide: true,
          onChangePage: setPage,
          onChangeRowsPerPage: setRowsPerPage,
          sort: false,
        }}
      />
    </>
  )
}
