import FormData from 'form-data'

import { api } from '@utils'

import { GetHomeCarouselItemResponse, UpdateHomeCarouselItemPayload } from '../types'

const getHomeCarouselItem = async (_: string, id: number) => {
  const response = await api.get<GetHomeCarouselItemResponse>(`/home-carousel-items/${id!}`)
  return response.data.homeCarouselItem
}

const updateHomeCarouselItem = async ({
  id,
  ...homeCarouselItemPayload
}: UpdateHomeCarouselItemPayload) => {
  const formData = new FormData()

  const { image, ...payload } = homeCarouselItemPayload

  if (image) formData.append('image', image)

  formData.append('homeCarouselItem', JSON.stringify(payload))

  try {
    const response = await api.put<UpdateHomeCarouselItemPayload>(
      `/home-carousel-items/${id}`,
      formData,
    )
    return response.data
  } catch (err) {
    throw err.response.data.error
  }
}

export const UpdateCarouselItemApi = { getHomeCarouselItem, updateHomeCarouselItem }
