import * as yup from 'yup'

export const validationSchema = yup.object().shape({
  name: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().email().required(),
  country: yup.string().required(),
  specialty: yup.string().required(),
  title: yup.string().required(),
  status: yup.string(),
  changePasswordSwhitch: yup.boolean(),
  password: yup.string().when('changePasswordSwhitch', {
    is: true,
    then: yup
      .string()
      .trim()
      .required()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z#?!@$%^&*\d]{8,}$/,
        'La contraseña no es correcta. Solo permite mayúscula/s, minúscula/s, número/s y al menos 8 caracteres.',
      ),
  }),
  password2: yup.string().when('changePasswordSwhitch', {
    is: true,
    then: yup.string().oneOf([yup.ref('password')], 'Las contraseñas deben coincidir'),
  }),
})
