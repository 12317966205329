import React, { FC } from 'react'
import { Paper } from '@material-ui/core'
import { useMutation, queryCache, useQuery } from 'react-query'
import { RouteComponentProps } from 'react-router'

import { QUERY_KEYS } from '@api'
import { ErrorResponse } from '@types'
import { useCustomSnackbar } from '@hooks'

import { Header } from '../../components'
import { UpdateCarouselItemApi } from './api'
import { useStyles } from './styles'
import { CarouselForm } from '../Components'

export type Color = 'success' | 'info' | 'warning' | 'error'

export const HomeCarouselDetails: FC<RouteComponentProps<{ id: string }>> = ({ match }) => {
  const { id } = match!.params
  const classes = useStyles()
  const snackbar = useCustomSnackbar()

  const { data: homeCarouselItem } = useQuery(
    [QUERY_KEYS.HOME_CAROUSEL_ITEMS, id],
    UpdateCarouselItemApi.getHomeCarouselItem,
  )

  const [update, { isLoading, isError, isSuccess }] = useMutation(
    UpdateCarouselItemApi.updateHomeCarouselItem,
    {
      onSuccess: async () => {
        await queryCache.invalidateQueries(QUERY_KEYS.HOME_CAROUSEL_ITEMS)
        snackbar.showSuccess('¡Guardado con éxito!')
      },
      onError: (error: ErrorResponse) => {
        snackbar.showError(error.message)
      },
    },
  )

  return (
    <>
      <Header title="Editar Item del carrusel" />

      <Paper elevation={1} className={classes.paper}>
        {homeCarouselItem && (
          <CarouselForm
            homeCarouselItem={homeCarouselItem}
            handleSubmit={homeCarouselItemPayload => {
              if (!homeCarouselItem) return
              update({ id: homeCarouselItem.id, ...homeCarouselItemPayload })
            }}
            isLoading={isLoading}
            isSuccess={isSuccess}
            isError={isError}
          />
        )}
      </Paper>
    </>
  )
}
