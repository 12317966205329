import { useEffect, useState } from 'react'
import { useMutation, useQueryCache } from 'react-query'
import { useHistory } from 'react-router'

import { STORAGE_KEYS } from '@constants'
import { removeAuthHeader, setAuthHeader } from '@utils'
import { GlobalUsersApi, QUERY_KEYS } from '@api'
import { LoginResponse, CacheUser, ErrorResponse } from '@types'

import { useLocalStorage } from '../useLocalStorage'
import { useCustomSnackbar } from '../useCustomSnackbar'

const useAuth = () => {
  const cache = useQueryCache()
  const history = useHistory()
  const snackBar = useCustomSnackbar()

  const [userToken, setUserToken, clear] = useLocalStorage<string>(STORAGE_KEYS.USER_TOKEN)

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    !!userToken && !!cache.getQueries(QUERY_KEYS.USER),
  )

  const [login, { isLoading: dsa }] = useMutation(GlobalUsersApi.login, {
    onSuccess: ({ user, token }: LoginResponse) => {
      setAuthHeader(token)

      cache.setQueryData(QUERY_KEYS.USER, user)

      setUserToken(token)

      if (!isAuthenticated) setIsAuthenticated(true)
    },
    onError: (error: ErrorResponse) => {
      snackBar.showError(error.message)
    },
  })

  const [loginWithToken, { isLoading: asd }] = useMutation(GlobalUsersApi.loginWithToken, {
    onSuccess: ({ user }: CacheUser) => {
      cache.setQueryData(QUERY_KEYS.USER, user)
      if (!isAuthenticated) setIsAuthenticated(true)
    },
    onError: (error: ErrorResponse) => {
      snackBar.showError(error.message)
    },
  })

  const logout = () => {
    removeAuthHeader()
    cache.removeQueries(QUERY_KEYS.USER)
    clear()
    history.push('/login')
  }

  useEffect(() => {
    if (userToken) {
      setAuthHeader(userToken)
      if (!isAuthenticated) loginWithToken()
    } else {
      removeAuthHeader()
    }
  }, [loginWithToken, userToken, isAuthenticated])

  return {
    logout,
    login,
    loginWithToken,
    isAuthenticated,
    isLoading: asd || dsa,
  }
}

export { useAuth }
