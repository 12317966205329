import { WebinarStatus } from '@types'
import { api } from '@utils'

import { UpdateStatusResponse } from '../../types'

const deleteWebinar = async (id: number) => {
  await api.delete(`/webinars/${id}`)
}

const updateWebinarStatus = async (id: number): Promise<UpdateStatusResponse> => {
  const formData = new FormData()
  formData.append('webinar', JSON.stringify({ status: WebinarStatus.discarded }))

  try {
    const response = await api.put<UpdateStatusResponse>(`/webinars/${id}`, formData)
    return response.data
  } catch (err) {
    throw err.response.data.error
  }
}

export const RemoveModalApi = { deleteWebinar, updateWebinarStatus }
