import { makeStyles } from '@material-ui/core'

import { COLORS } from '@styles'

const useStyles = makeStyles(theme => ({
  search: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    color: COLORS.GRAY_DARK,
  },
  navLink: {
    fontSize: 12,
    margin: '0 15px',
    padding: '10px 0',
    textDecoration: 'none',
    color: COLORS.GRAY_DARK,
    '&.active': {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
  },
  inputWrapper: {
    display: 'flex',
    alignItems: 'center',
    border: 'solid 1px #707070',
    backgroundColor: '#ffffff',
    height: 42,
    borderRadius: 20,
    marginLeft: 10,
    width: 300,
  },
  searchIcon: {
    width: 32,
    height: 32,
    padding: '0 10px',
  },
  inputInput: {
    height: 18,
    margin: '7px 0 7px 40px',
    fontSize: 14,
    lineHeight: '1.29',
    letterSpacing: '0.92px',
    textAlign: 'left',
    color: COLORS.GRAY_DARK,
  },
}))

export { useStyles }
