import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  main: {
    backgroundColor: theme.palette.background.default,
    flexGrow: 1,
    height: '100vh',
    padding: theme.spacing(3),
    paddingTop: 100,
  },
}))
