import React, { FC } from 'react'
import { Modal, Fade, Container, Card, CardHeader, CardContent } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import EmailIcon from '@material-ui/icons/Email'
import moment from 'moment'
import LocalHospitalIcon from '@material-ui/icons/LocalHospital'
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined'

import { FormMedica } from '@types'
import { STRINGS } from '@constants'
import { useGlobalStyles } from '@styles'

import { useStyles } from './styles'

interface Props {
  isOpen: boolean
  handleClose: () => void
  form: FormMedica | null
}

export const FormMedicaModal: FC<Props> = ({ isOpen, handleClose, form }) => {
  const globalClasses = useGlobalStyles()
  const classes = useStyles()

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={globalClasses.modal}
    >
      <Fade in={isOpen} timeout={500}>
        <Container className={globalClasses.modalContainer}>
          <Card className={globalClasses.modalCard}>
            <CloseIcon className={globalClasses.modalCloseIcon} onClick={handleClose} />
            <CardHeader
              title={
                <span className={classes.name}>{`${moment(form?.created_at).format(
                  STRINGS.DATE_FORMAT,
                )}`}</span>
              }
              subheader={`${form?.subject}`}
            />
            <CardContent>
              <div className={classes.email}>
                <EmailIcon /> Para: {form?.mailTo}
              </div>
              <div className={classes.email}>
                <EmailOutlinedIcon /> De: {form?.mailFrom}
              </div>
              <div className={classes.text}>
                Patología: {form?.therapeuticAreas.name}
              </div>
              <div className={classes.text}>
                ¿Enviar copia? {form?.sendMeACopy === 1 ? 'Si' : 'No'}
              </div>
              <div className={classes.text}>
                <LocalHospitalIcon /> {form?.request}
              </div>
            </CardContent>
          </Card>
        </Container>
      </Fade>
    </Modal>
  )
}
