import React, { FC } from 'react'
import { Route, Switch, RouteComponentProps } from 'react-router-dom'

import { Speakers } from './Speakers'
import { AddSpeaker } from './AddSpeaker'
import { SpeakerDetails } from './SpeakerDetails'

export const SpeakersRouter: FC<RouteComponentProps> = ({ match }) => {
  return (
    <Switch>
      <Route exact path={match.path} component={Speakers} />
      <Route exatc path={`${match.path}/add`} component={AddSpeaker} />
      <Route exatc path={`${match.path}/edit/:id`} component={SpeakerDetails} />
    </Switch>
  )
}
