export const QUERY_KEYS = {
  USER: 'user',
  FORMS: 'forms',
  TAGS: 'tags',
  WEBINARS: 'webinars',
  REGISTRANTS: 'registrants',
  SPEAKERS: 'speakers',
  THERAPEUTIC_AREAS: 'therapeuticAreas',
  HOME_CAROUSEL_ITEMS: 'homeCarouselItems',
}
