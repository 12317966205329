const COLORS = {
  PRIMARY: '#fa9847',
  SECONDARY: '#fbf2d6',
  WHITE: '#fff',
  GREEN: '#4baf58',
  GRAY: '#9f9e9e',
  GRAY_DARK: '#707070',
  GRAY_LIGHT: '#f9f9f9',
  GRAY_LOGIN: '#e4e4e4',
  ORANGE_LIGHT: '#fbf2d6',
  ORANGE_MEDIUM: '#ffb980',
}

export { COLORS }
