import React, { useEffect } from 'react'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import {
  Grid,
  TextField,
  Typography,
  Divider,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Switch,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { yupResolver } from '@hookform/resolvers/yup'
import { useHistory } from 'react-router'

import { useGlobalStyles } from '@styles'
import { UserStatus } from '@types'

import { UserFormProps, UserFormType } from './types'
import { useStyles } from './styles'
import { validationSchema } from './validations'
import { specialties } from './specialties'

export const UserForm: React.FC<UserFormProps> = ({
  user,
  handleSubmit: submit,
  isLoading,
  isSuccess,
}) => {
  const globalClasses = useGlobalStyles()
  const classes = useStyles()
  const { push } = useHistory()

  const defaultValues = {
    name: user?.name,
    lastName: user?.lastName,
    email: user?.email,
    country: user?.country,
    specialty: user?.specialty,
    title: user?.title,
    status: user?.status,
  }

  const { handleSubmit, errors, control, formState, setValue } = useForm<UserFormType>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })

  const onSubmit: SubmitHandler<UserFormType> = async (data) => {
    submit({ isVerified: true, status: UserStatus.accepted, ...data })
  }

  useEffect(() => {
    if (isSuccess) setValue('changePasswordSwhitch', false)
  }, [isSuccess, setValue])
  return (
    <form className={globalClasses.form} onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h5" className={classes.title}>
        Datos personales
      </Typography>
      <Grid container spacing={3}>
        <Grid item sm={2} xs={5}>
          <FormControl variant="outlined" size="small" margin="normal" style={{ width: '100%' }}>
            <InputLabel id="minutes-label">Título</InputLabel>
            <Controller
              name="title"
              control={control}
              defaultValue=""
              as={
                <Select labelId="minutes-label" id="minutes" label="Título *">
                  <MenuItem value="Dra.">Dra.</MenuItem>
                  <MenuItem value="Dr.">Dr.</MenuItem>
                </Select>
              }
            />
          </FormControl>
          {errors.title && <Alert severity="error">Este campo es obligatorio</Alert>}
        </Grid>
        <Grid item sm={5} xs={7}>
          <Controller
            name="name"
            control={control}
            as={
              <TextField
                label="Nombre *"
                placeholder="Ingresar nombre"
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                autoComplete="off"
                size="small"
              />
            }
          />
          {errors.name && <Alert severity="error">Este campo es obligatorio</Alert>}
        </Grid>
        <Grid item sm={5} xs={12}>
          <Controller
            name="lastName"
            control={control}
            as={
              <TextField
                label="Apellido *"
                placeholder="Ingresar appelido"
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                autoComplete="off"
                size="small"
              />
            }
          />
          {errors.lastName && <Alert severity="error">Este campo es obligatorio</Alert>}
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="email"
            control={control}
            as={
              <TextField
                label="Email *"
                type="email"
                placeholder="Ingresar correo electrónico"
                fullWidth
                margin="normal"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                autoComplete="off"
                size="small"
                disabled={!!user}
              />
            }
          />
          {errors.email && <Alert severity="error">Este campo es obligatorio</Alert>}
        </Grid>
        <Grid item sm={6} xs={12}>
          <FormControl variant="outlined" size="small" margin="normal" style={{ width: '100%' }}>
            <InputLabel id="minutes-label">Especialidad</InputLabel>
            <Controller
              name="specialty"
              control={control}
              defaultValue=""
              as={
                <Select labelId="minutes-label" id="minutes" label="Especialidad">
                  {specialties.map(speciality => (
                    <MenuItem value={speciality}>{speciality}</MenuItem>
                  ))}
                </Select>
              }
            />
          </FormControl>
          {errors.specialty && <Alert severity="error">Este campo es obligatorio</Alert>}
        </Grid>
        <Grid item sm={6} xs={12}>
          <Controller
            name="country"
            control={control}
            as={
              <TextField
                label="País *"
                placeholder="Ingresar país"
                fullWidth
                margin="normal"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                autoComplete="off"
                size="small"
              />
            }
          />
          {errors.country && <Alert severity="error">Este campo es obligatorio</Alert>}
        </Grid>
        {/* TODO (Queda comentado xq el cliente puede solicitarlo mas adelante) <Grid item lg={6} md={12}>
          <List>
            <ListItem>
              <ListItemText primary="Estado:" />
              <ListItemSecondaryAction>
                <FormControl
                  size="small"
                  variant="outlined"
                  margin="normal"
                  style={{ marginRight: 0 }}
                >
                  <InputLabel id="status-label">Estado</InputLabel>
                  <Controller
                    name="status"
                    control={control}
                    render={() => (
                      <Select
                        onChange={(event: React.ChangeEvent<{ value: UserStatus | unknown }>) => {
                          setValue('status', event.target.value as UserStatus)
                        }}
                        labelId="status-label"
                        id="Estado"
                        label="Estado"
                        defaultValue={user?.status}
                        style={{ width: 300 }}
                      >
                        <MenuItem value="toModerate" selected>
                          En Moderación
                        </MenuItem>
                        <MenuItem value="accepted">Aceptado</MenuItem>
                        <MenuItem value="rejected">Rechazado</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
          {errors.status && <Alert severity="error">Este campo es obligatorio</Alert>}
        </Grid> */}
      </Grid>

      <Divider className={classes.divider} />

      <div className={classes.actions}>
        <Button
          onClick={() => push('/users')}
          variant="contained"
          color="secondary"
          className={classes.actionButton}
        >
          Salir
        </Button>
        <Button variant="contained" color="primary" className={classes.actionButton} type="submit">
          {user?.id ? 'Actualizar' : 'Guardar'}
        </Button>
        {isLoading && <CircularProgress disableShrink size={26} />}
      </div>
    </form>
  )
}
