import React, { FC } from 'react'
import { Paper } from '@material-ui/core'
import { useMutation, queryCache } from 'react-query'

import { QUERY_KEYS } from '@api'
import { ErrorResponse } from '@types'
import { useCustomSnackbar } from '@hooks'

import { Header } from '../../components'
import { HomeCarouselApi } from './api'
import { useStyles } from './styles'
import { CarouselForm } from '../Components'

export type Color = 'success' | 'info' | 'warning' | 'error'

export const AddHomeCarousel: FC = () => {
  const classes = useStyles()
  const snackbar = useCustomSnackbar()

  const [add, { isLoading }] = useMutation(HomeCarouselApi.addCarousel, {
    onSuccess: async () => {
      await queryCache.invalidateQueries(QUERY_KEYS.HOME_CAROUSEL_ITEMS)
      snackbar.showSuccess('¡Guardado con éxito!')
    },
    onError: (error: ErrorResponse) => {
      snackbar.showError(error.message)
    },
  })

  return (
    <>
      <Header title="Agregar item al carrusel" />

      <Paper elevation={1} className={classes.paper}>
        <CarouselForm handleSubmit={add} isLoading={isLoading} />
      </Paper>
    </>
  )
}
