import React, { FC } from 'react'
import { Button, Fade, Modal, Typography } from '@material-ui/core'
import { queryCache, useMutation } from 'react-query'
import CloseIcon from '@material-ui/icons/Close'

import { QUERY_KEYS } from '@api'
import { useCustomSnackbar } from '@hooks'
import { ErrorResponse } from '@types'

import { useStyles } from './styles'
import { RemoveModalApi } from './api'

interface Props {
  isOpen: boolean
  handleClose: () => void
  id: number
}

export const RemoveWebinarModal: FC<Props> = ({ isOpen, handleClose, id }) => {
  const classes = useStyles()
  const snackbar = useCustomSnackbar()

  const [remove] = useMutation(RemoveModalApi.updateWebinarStatus, {
    onSuccess: async () => {
      await queryCache.invalidateQueries(QUERY_KEYS.WEBINARS)
      snackbar.showSuccess('¡El webinar se eliminó con éxito!')
      handleClose()
    },
    onError: (error: ErrorResponse) => {
      snackbar.showError(error.message)
    },
  })

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-remove"
      aria-describedby="modal-remove-webinar"
    >
      <Fade in={isOpen} timeout={500}>
        <div className={classes.paper}>
          <CloseIcon className={classes.closeIcon} onClick={handleClose} />

          <Typography className={classes.text}>
            Esta seguro que desea eliminar el Webinar
          </Typography>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={() => remove(id)}
            className={classes.button}
          >
            Eliminar
          </Button>
          <Button
            onClick={handleClose}
            variant="contained"
            color="secondary"
            className={classes.button}
          >
            Cancelar
          </Button>
        </div>
      </Fade>
    </Modal>
  )
}
