import { api } from '@utils'

export interface GetFormsListFile {
  file: {
    type: 'Buffer'
    data: number[]
  }
}

interface GetFormsPayload {
  page?: number
  limit?: number
  date?: string
}

const downloadList = async ({ date = '' }: GetFormsPayload): Promise<GetFormsListFile> => {
  const params = {
    date: date || null,
  }

  const response = await api.get<GetFormsListFile>('/form-medica/csv', { params })

  return response.data
}

export const FormMedicaApi = { downloadList }
