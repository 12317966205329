import React from 'react'
import { BrowserRouter as Router, Switch, Route, useHistory } from 'react-router-dom'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { SnackbarProvider } from 'material-ui-snackbar-provider'
import MomentUtils from '@date-io/moment'
import { ThemeProvider } from '@material-ui/core'
import moment from 'moment'
import 'moment/locale/es'
// TODO: A la espera de la migración - import { ReactQueryCacheProvider, QueryCache } from 'react-query'

import { PrivateRoute, CustomSnackbar } from '@components'
import { theme } from '@styles'

import Main from './Main'
import Login from './Login'
import './App.css'

function App() {
  const history = useHistory()
  // TODO: A la espera de la migración - const queryCache = new QueryCache()
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider SnackbarComponent={CustomSnackbar}>
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
          {/* 
          // TODO: A la espera de la migración - 
          <ReactQueryCacheProvider queryCache={queryCache}> */}
          <Router>
            <Switch>
              <Route path="/login" component={Login} />
              <PrivateRoute history={history}>
                <Main />
              </PrivateRoute>
            </Switch>
          </Router>
          {/* </ReactQueryCacheProvider> */}
        </MuiPickersUtilsProvider>
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default App
