import { HomeCarouselItem } from '@types'
import { api } from '@utils'

interface GetCarosuelResponse {
  homeCarouselItems: HomeCarouselItem[]
}

const getHomeCarouselItems = async (): Promise<HomeCarouselItem[]> => {
  const response = await api.get<GetCarosuelResponse>('/home-carousel-items')
  return response.data.homeCarouselItems
}

export const GlobalHomeCarouselItemsApi = { getHomeCarouselItems }
