export const specialties = [
  'Bioquímica',
  'Cardiología',
  'Cirugía General',
  'Clínica Médica',
  'Farmacéutico',
  'Gastroenterología',
  'Hematología',
  'Inmunología',
  'Oncología',
  'Pediatría',
  'Traumatología',
  'Otra',
]
