import React, { FC } from 'react'
import {
  Modal,
  Fade,
  Typography,
  Container,
  Card,
  CardHeader,
  Avatar,
  CardContent,
} from '@material-ui/core'
import parse from 'html-react-parser'
import CloseIcon from '@material-ui/icons/Close'
import EmailIcon from '@material-ui/icons/Email'

import { Speaker } from '@types'
import { useGlobalStyles } from '@styles'

import { useStyles } from './styles'

interface Props {
  isOpen: boolean
  handleClose: () => void
  speaker: Speaker | null
}

export const SpeakerModal: FC<Props> = ({ isOpen, handleClose, speaker }) => {
  const globalClasses = useGlobalStyles()
  const classes = useStyles()

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={globalClasses.modal}
    >
      <Fade in={isOpen} timeout={500}>
        <Container className={globalClasses.modalContainer}>
          <Card className={globalClasses.modalCard}>
            <CloseIcon className={globalClasses.modalCloseIcon} onClick={handleClose} />
            <CardHeader
              avatar={<Avatar src={speaker?.profilePictureUrl} className={classes.avatar} />}
              title={
                <span className={classes.name}>{`${speaker?.name}, ${speaker?.lastName}`}</span>
              }
              subheader={`${speaker?.specialty} / ${speaker?.country}`}
            />
            <CardContent>
              <div className={classes.email}>
                <EmailIcon /> {speaker?.email}
              </div>
              {speaker?.biography && (
                <Typography variant="body1">{parse(speaker.biography)}</Typography>
              )}
            </CardContent>
          </Card>
        </Container>
      </Fade>
    </Modal>
  )
}
