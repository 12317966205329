import React, { FC } from 'react'
import { Button, Paper, Typography } from '@material-ui/core'
import { RouteComponentProps } from 'react-router'
import { useMutation, queryCache, useQuery } from 'react-query'

import { QUERY_KEYS } from '@api'
import { useCSVReport, useCustomSnackbar } from '@hooks'
import { ErrorResponse } from '@types'
import FileCSVIcon from '@assets/icons/file-excel-solid.svg'

import { UpdateWebinarApi } from './api'
import { Header, MuiTable } from '../../components'
import { useStyles } from './styles'
import { WebinarForm } from '../components'

const columns = [
  { label: 'Nombre', name: 'name' },
  { label: 'Apellido', name: 'lastName' },
  { label: 'Email', name: 'email' },
  { label: 'Origen', name: 'origin' },
  { label: 'Asistió', name: 'hasParticipated' },
]

export const WebinarDetails: FC<RouteComponentProps<{ id: string }>> = ({ match }) => {
  const { id } = match!.params
  const classes = useStyles()
  const snackbar = useCustomSnackbar()

  const { handleCSVReport } = useCSVReport()

  const { data: webinar } = useQuery([QUERY_KEYS.WEBINARS, id], UpdateWebinarApi.getWebinar)

  const [update, { isLoading }] = useMutation(UpdateWebinarApi.updateWebinar, {
    onSuccess: () => {
      queryCache.invalidateQueries(QUERY_KEYS.WEBINARS)
      snackbar.showSuccess('¡El webinar se actualizó con éxito!')
    },
    onError: (error: ErrorResponse) => {
      snackbar.showError(error.message)
    },
  })

  return (
    <>
      <Header title="Editar Webinar" />

      <Paper elevation={1} className={classes.paper}>
        {webinar && (
          <WebinarForm
            webinar={webinar}
            isLoading={isLoading}
            handleSubmit={webinarPayload => {
              if (!webinar) return
              update({ id: webinar.id, payload: webinarPayload })
            }}
          />
        )}
      </Paper>
      {webinar?.registrants && (
        <Paper elevation={1} className={classes.paper}>
          <Typography variant="h5" className={classes.title}>
            Lista de registrantes
          </Typography>

          <Button
            type="submit"
            variant="outlined"
            color="primary"
            fullWidth
            className={classes.button}
            onClick={() => handleCSVReport(UpdateWebinarApi.exportRegistrantsCsvUrl(webinar.id))}
          >
            <img src={FileCSVIcon} alt="File CSV Icon" height="16" style={{ marginRight: 10 }} />
            EXPORTAR A CSV
          </Button>

          <MuiTable
            title=""
            columns={columns}
            data={webinar.registrants.map(registrant => {
              return {
                ...registrant,
                hasParticipated: registrant.hasParticipated ? 'Si' : 'No',
              }
            })}
          />
        </Paper>
      )}
    </>
  )
}
