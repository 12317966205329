import { makeStyles } from '@material-ui/core'

import { COLORS } from '@styles'

const useStyle = makeStyles(theme => ({
  navLink: {
    fontSize: 13,
    margin: '0 15px',
    padding: '10px 0',
    textDecoration: 'none',
    color: COLORS.GRAY_DARK,
    '&.active': {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
  },
}))

export { useStyle }
