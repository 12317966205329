import { api } from '@utils'
import { Tag } from '@types'

interface GetTagsPayload {
  page?: number
  limit?: number
  name?: string
  isActive?: true
}

interface GetTagsResponse {
  count: number
  tags: Tag[]
}

const getTags = async (
  _: string,
  { page = 0, limit = 10, name = '', isActive }: GetTagsPayload,
): Promise<GetTagsResponse> => {
  const params = {
    name: name || undefined,
    offset: page * limit,
    limit,
    isActive,
  }
  const response = await api.get<GetTagsResponse>('/tags', { params })

  return response.data
}

export const GlobalTagsApi = { getTags }
