import React, { FC } from 'react'
import { Paper } from '@material-ui/core'
import { RouteComponentProps } from 'react-router'
import { useMutation, queryCache, useQuery } from 'react-query'
import { QUERY_KEYS } from '@api'
import { useCustomSnackbar } from '@hooks'
import { ErrorResponse } from '@types'

import { UpdateSpeakersApi } from './api'
import { Header } from '../../components'
import { useStyles } from './styles'
import { SpeakerForm } from '../components'

export const SpeakerDetails: FC<RouteComponentProps<{ id: string }>> = ({ match }) => {
  const { id } = match!.params
  const classes = useStyles()
  const snackbar = useCustomSnackbar()

  const { data: speaker } = useQuery([QUERY_KEYS.SPEAKERS, id], UpdateSpeakersApi.getSpeaker)

  const [update, { isLoading }] = useMutation(UpdateSpeakersApi.updateSpeaker, {
    onSuccess: () => {
      queryCache.invalidateQueries(QUERY_KEYS.SPEAKERS)
      snackbar.showSuccess('¡El speaker se actualizó con éxito!')
    },
    onError: (error: ErrorResponse) => {
      snackbar.showError(error.message)
    },
  })

  return (
    <>
      <Header title="Editar Speaker" />

      <Paper elevation={1} className={classes.paper}>
        {speaker && (
          <SpeakerForm
            handleSubmit={speakerPayload => {
              if (!speaker) return
              update({ id: speaker.id, ...speakerPayload })
            }}
            speaker={speaker}
            isLoading={isLoading}
            isEdit
          />
        )}
      </Paper>
    </>
  )
}
