import FormData from 'form-data'

import { api } from '@utils'

import {
  GetWebinarsPayload,
  GetWebinarsResponse,
  UpdateStatusPayload,
  UpdateStatusResponse,
} from '../types'

const getWebinars = async (
  _: string,
  {
    page = 0,
    limit = 10,
    title,
    therapeuticAreaId,
    pastWebinars,
    nextWebinars,
  }: GetWebinarsPayload,
): Promise<GetWebinarsResponse> => {
  const params = {
    title: title || undefined,
    therapeuticAreaId: therapeuticAreaId || undefined,
    pastWebinars: pastWebinars || undefined,
    nextWebinars: nextWebinars || undefined,
    offset: page * limit,
    limit,
  }
  const response = await api.get<GetWebinarsResponse>('/webinars', { params })

  return response.data
}

const update = async ({
  id,
  isActive,
  isSalient,
}: UpdateStatusPayload): Promise<UpdateStatusResponse> => {
  const formData = new FormData()
  formData.append('webinar', JSON.stringify({ isActive, isSalient }))

  try {
    const response = await api.put<UpdateStatusResponse>(`/webinars/${id}`, formData)
    return response.data
  } catch (err) {
    throw err.response.data.error
  }
}

export const WebinarsApi = { getWebinars, update }
