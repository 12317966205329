import React, { FC, useMemo } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

import { UserStatus } from '@types'

import { useStyle } from './styles'

// TODO handle params - isActive NavLink prop

export const CategoriesFilter: FC = () => {
  const classes = useStyle()
  const { search } = useLocation()

  const params = useMemo(() => new URLSearchParams(search), [search])

  return (
    <>
      <NavLink
        to={() => {
          params.delete('status')
          return {
            pathname: '/users',
          }
        }}
        className={classes.navLink}
        isActive={() => {
          const p = new URLSearchParams(search)
          return !p.has('status')
        }}
        exact
      >
        Todos los usuarios
      </NavLink>
      <NavLink
        to={() => {
          params.set('status', UserStatus.accepted)
          return {
            pathname: '/users',
            search: `?${params.toString()}`,
          }
        }}
        className={classes.navLink}
        key={UserStatus.accepted}
        isActive={() => {
          const p = new URLSearchParams(search)
          return p.get('status') === UserStatus.accepted
        }}
      >
        Aceptados
      </NavLink>
      <NavLink
        to={() => {
          params.set('status', UserStatus.toModerate)
          return {
            pathname: '/users',
            search: `?${params.toString()}`,
          }
        }}
        className={classes.navLink}
        key={UserStatus.toModerate}
        isActive={() => {
          const p = new URLSearchParams(search)
          return p.get('status') === UserStatus.toModerate
        }}
      >
        En Moderación
      </NavLink>
      <NavLink
        to={() => {
          params.set('status', UserStatus.rejected)
          return {
            pathname: '/users',
            search: `?${params.toString()}`,
          }
        }}
        className={classes.navLink}
        key={UserStatus.rejected}
        isActive={() => {
          const p = new URLSearchParams(search)
          return p.get('status') === UserStatus.rejected
        }}
      >
        Rechazados
      </NavLink>
    </>
  )
}
