import React, { FC, useMemo, useState } from 'react'
import { Typography, Box, Switch, Tooltip } from '@material-ui/core'
import { useQuery, useMutation, queryCache } from 'react-query'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add'

import { QUERY_KEYS, GlobalTagsApi } from '@api'
import { ErrorResponse, Tag } from '@types'
import { useCustomSnackbar } from '@hooks'

import { MuiTable, Search } from '../components'
import { TagsApi } from './api'
import { EditTagModal, AddTagModal, RemoveTagModal } from './components'
import { useStyles } from './styles'

const columns = [
  { label: 'Nombre', name: 'name' },
  { label: 'Estado', name: 'status' },
  { label: 'Acciones', name: 'actions' },
]

export const Tags: FC = () => {
  const classes = useStyles()
  const snackbar = useCustomSnackbar()

  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [isEditTagModalOpen, setIsEditTagModalOpen] = useState(false)
  const [isAddTagModalOpen, setIsAddTagModalOpen] = useState(false)
  const [isRemoveTagModal, setIsRemoveTagModalOpen] = useState(false)
  const [tagSelected, setTagSelected] = useState<Tag | null>(null)
  const [itemToEdit, setItemToEdit] = useState<Tag | null>(null)
  const [search, setSearch] = useState('')

  const { data: tags } = useQuery(
    [QUERY_KEYS.TAGS, { page, limit: rowsPerPage, name: search }],
    GlobalTagsApi.getTags,
  )

  const [update] = useMutation(TagsApi.updateTag, {
    onSuccess: () => {
      queryCache.invalidateQueries(QUERY_KEYS.TAGS)
      snackbar.showSuccess('¡El item se actualizó con éxito!')
    },
    onError: (error: ErrorResponse) => {
      snackbar.showError(error.message)
    },
  })

  const onHandleChangeSearch = (text: string) => {
    setPage(0)
    setSearch(text)
  }

  const tagsData = useMemo(
    () =>
      tags?.tags.map(tag => ({
        ...tag,
        status: (
          <Tooltip key={tag.name} title="Cambiar estado" placement="top">
            <Switch
              id={`check${tag.id}`}
              checked={tag.isActive}
              onChange={() => update({ ...tag, isActive: !tag.isActive })}
              name={`check${tag.id}`}
              value={tag.id}
            />
          </Tooltip>
        ),
        actions: [
          <div key={tag.name}>
            <Tooltip title="Editar etiqueta" placement="top">
              <IconButton
                color="primary"
                aria-label="Editar etiqueta"
                component="span"
                onClick={() => {
                  setItemToEdit(tag)
                  setIsEditTagModalOpen(true)
                }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Eliminar etiqueta" placement="top">
              <IconButton
                color="secondary"
                aria-label="Eliminar etiqueta"
                component="span"
                onClick={() => {
                  setTagSelected(tag)
                  setIsRemoveTagModalOpen(true)
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>,
        ],
      })),
    [tags, update],
  )

  return (
    <>
      <Box display="flex" justifyContent="space-between" className={classes.header}>
        <Typography variant="h4" color="textPrimary">
          Tags
        </Typography>
        <Search placeholder="Buscar tags" handleTextChange={onHandleChangeSearch} />
        {itemToEdit && (
          <EditTagModal
            isOpen={isEditTagModalOpen}
            handleClose={() => setIsEditTagModalOpen(false)}
            itemToEdit={itemToEdit!}
          />
        )}
        <RemoveTagModal
          isOpen={isRemoveTagModal}
          handleClose={() => setIsRemoveTagModalOpen(false)}
          id={tagSelected?.id!}
        />
        <Tooltip title="Agregar nueva etiqueta" placement="top">
          <IconButton
            color="primary"
            aria-label="Agregar etiqueta"
            component="span"
            onClick={() => setIsAddTagModalOpen(true)}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
        <AddTagModal isOpen={isAddTagModalOpen} handleClose={() => setIsAddTagModalOpen(false)} />
      </Box>
      <MuiTable
        title=""
        columns={columns}
        data={tagsData ?? []}
        options={{
          page,
          count: tags?.count ?? 0,
          serverSide: true,
          onChangePage: setPage,
          onChangeRowsPerPage: numberOfRows => {
            setPage(0)
            setRowsPerPage(numberOfRows)
          },
          sort: false,
        }}
      />
    </>
  )
}
