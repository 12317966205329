import React from 'react'
import clsx from 'clsx'
import MUIDataTable, { MUIDataTableProps } from 'mui-datatables'

import { useStyles } from './styles'

export const MuiTable = (props: MUIDataTableProps) => {
  const classes = useStyles()
  const { options, ...restProps } = props

  return (
    <MUIDataTable
      {...restProps}
      options={{
        setRowProps: (row: void[], rowIndex: number) => {
          return {
            className: clsx({
              [classes.greyLine]: rowIndex % 2 !== 0,
            }),
          }
        },
        download: false,
        print: false,
        viewColumns: false,
        filter: false,
        search: false,
        selectableRows: 'none',
        textLabels: {
          body: {
            noMatch: 'No hay nada todavía',
          },
          pagination: {
            next: 'Página siguiente',
            previous: 'Página previa',
            rowsPerPage: 'Filas por página:',
            displayRows: 'de',
          },
          toolbar: {
            search: 'Buscar',
          },
        },
        ...options,
      }}
    />
  )
}
