import React, { useState, useMemo, FC } from 'react'
import { Tooltip, IconButton, Avatar, LinearProgress } from '@material-ui/core'
import { queryCache, useMutation, useQuery } from 'react-query'
import { useHistory } from 'react-router'
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add'
import VisibilityIcon from '@material-ui/icons/Visibility'
import BlockIcon from '@material-ui/icons/Block'
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver'

import { QUERY_KEYS, GlobalSpeakersApi } from '@api'
import { ErrorResponse, Speaker } from '@types'
import { useGlobalStyles } from '@styles'
import { useCustomSnackbar } from '@hooks'

import { MuiTable, Header, Search } from '../../components'
import { SpeakerModal } from '../components'

const columns = [
  { label: 'Imagen', name: 'image' },
  { label: 'Apellido', name: 'lastName' },
  { label: 'Nombre', name: 'name' },
  { label: 'Especialidad', name: 'specialty' },
  { label: 'Acciones', name: 'actions' },
]

export const Speakers: FC = () => {
  const history = useHistory()
  const globalClasses = useGlobalStyles()
  const snackbar = useCustomSnackbar()

  const [isInfoSpeakerModalOpen, setIsInfoSpeakerModalOpen] = useState(false)
  const [SpeakerSelected, setSpeakerSelected] = useState<Speaker | null>(null)
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [search, setSearch] = useState('')

  const { data: speakers } = useQuery(
    [
      QUERY_KEYS.SPEAKERS,
      { page, limit: rowsPerPage, name: search, lastName: search, specialty: search },
    ],
    GlobalSpeakersApi.getSpeakers,
  )

  const [update, { isLoading }] = useMutation(GlobalSpeakersApi.updateStatus, {
    onSuccess: () => {
      queryCache.invalidateQueries(QUERY_KEYS.SPEAKERS)
      snackbar.showSuccess('¡El sepaker se actualizó con éxito!')
    },
    onError: (error: ErrorResponse) => {
      snackbar.showError(error.message)
    },
  })

  const speakersData = useMemo(
    () =>
      speakers?.speakers.map(speaker => ({
        ...speaker,
        image: <Avatar src={speaker.profilePictureUrl} />,
        actions: speaker.isActive ? (
          <div key={speaker?.name}>
            <Tooltip title="Biografía" placement="top">
              <IconButton
                color="primary"
                aria-label=""
                component="span"
                onClick={() => {
                  setSpeakerSelected(speaker)
                  setIsInfoSpeakerModalOpen(true)
                }}
              >
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Editar speaker" placement="top">
              <IconButton
                color="primary"
                aria-label="Editar speaker"
                component="span"
                onClick={() => history.push(`/speakers/edit/${speaker.id}`)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Deshabilitar speaker" placement="top">
              <IconButton
                color="secondary"
                aria-label="Actualizar speaker"
                component="span"
                onClick={() => {
                  update({
                    id: speaker.id,
                    isActive: !speaker.isActive,
                  })
                }}
              >
                <BlockIcon />
              </IconButton>
            </Tooltip>
          </div>
        ) : (
          <Tooltip title="Habilitar speaker" placement="top">
            <IconButton
              color="default"
              aria-label="Actualizar speaker"
              component="span"
              onClick={() => {
                update({
                  id: speaker.id,
                  isActive: !speaker.isActive,
                })
              }}
            >
              <RecordVoiceOverIcon />
            </IconButton>
          </Tooltip>
        ),
      })),
    [speakers, update, history],
  )

  const onHandleChangeSearch = (text: string) => {
    setPage(0)
    setSearch(text)
  }

  return (
    <>
      {isLoading && <LinearProgress className={globalClasses.progressBar} />}
      <Header title="Speakers">
        <Search placeholder="Buscar speaker" handleTextChange={onHandleChangeSearch} />
        <Tooltip title="Agregar nuevo speaker" placement="top">
          <IconButton
            color="primary"
            aria-label="agregar nuevo speaker"
            component="span"
            onClick={() => history.push('/speakers/add')}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      </Header>
      <SpeakerModal
        isOpen={isInfoSpeakerModalOpen}
        handleClose={() => setIsInfoSpeakerModalOpen(false)}
        speaker={SpeakerSelected}
      />
      <MuiTable
        title=""
        data={speakersData ?? []}
        columns={columns}
        options={{
          page,
          count: speakers?.count ?? 0,
          serverSide: true,
          onChangePage: setPage,
          onChangeRowsPerPage: numberOfRows => {
            setPage(0)
            setRowsPerPage(numberOfRows)
          },
          sort: false,
        }}
      />
    </>
  )
}
