import React, { useCallback, FC, useMemo } from 'react'
import { useDropzone } from 'react-dropzone'

import { useStyles } from './styles'

interface Props {
  onDropFile: (file: File | null) => void
  image: File | null
  defaultImage?: string
  height?: number | string
  width?: number | string
}

const FileDropzone: FC<Props> = ({
  onDropFile,
  image,
  defaultImage,
  height = 'unset',
  width = 'unset',
}) => {
  const classes = useStyles()

  const onDrop = useCallback(
    acceptedFiles => {
      onDropFile(acceptedFiles[0])
    },
    [onDropFile],
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxFiles: 1,
    onDrop,
  })

  const imageUrl = useMemo(() => (image ? URL.createObjectURL(image) : undefined), [image])

  return (
    <div className={classes.fileDropzone} style={{ height, width }}>
      <div
        className={classes.dropArea}
        {...getRootProps()}
        style={{ opacity: defaultImage || image ? 0.3 : 1 }}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <p>Arrastrar una imagen o hacer click para cargar una imagen de perfil</p>
        )}
      </div>
      {(imageUrl || defaultImage) && (
        <img className={classes.image} src={imageUrl ?? defaultImage} alt="Title" />
      )}
    </div>
  )
}

export { FileDropzone }
