import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  date: {
    display: 'flex',
    textAlign: 'center',
    marginBottom: '20px',
    marginRight: '10px',
  },
  box: {
    display: 'flex',
    flexFlow: 'wrap',
    justifyContent: 'flex-end',
    width: '100%',
  },
}))

export { useStyles }
