import React, { FC } from 'react'
import { Button, CircularProgress, Divider, TextField, Typography } from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Alert } from '@material-ui/lab'
import { useHistory } from 'react-router'

import { FileDropzone } from '@components'
import { useGlobalStyles } from '@styles'
import { HomeCarouselItem } from '@types'

import { useStyles } from './styles'
import { HomeCarouselItemFormType } from './types'

const validationSchema = yup.object().shape({
  title: yup.string(),
  defaultImage: yup.string(),
  image: yup.mixed().when('defaultImage', {
    is: yup.string(),
    then: yup.mixed().required(),
  }),
  description: yup.string(),
  link: yup.string(),
})

interface Props {
  homeCarouselItem?: HomeCarouselItem
  handleSubmit: (carouselImage: HomeCarouselItemFormType) => void
  isLoading?: boolean
  isSuccess?: boolean
  isError?: boolean
  errorMessage?: string
}

export const CarouselForm: FC<Props> = ({ homeCarouselItem, handleSubmit: submit, isLoading }) => {
  const globalClasses = useGlobalStyles()
  const classes = useStyles()
  const { push } = useHistory()

  const defaultValues = {
    title: homeCarouselItem?.title ?? undefined,
    defaultImage: homeCarouselItem?.imageUrl,
    image: '',
    description: homeCarouselItem?.description ?? undefined,
    link: homeCarouselItem?.link ?? undefined,
  }

  const { control, handleSubmit, errors } = useForm<HomeCarouselItemFormType>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })

  return (
    <form className={globalClasses.form} onSubmit={handleSubmit(submit)}>
      <Typography>Cargar imagen</Typography>

      <Controller
        name="image"
        control={control}
        render={props => (
          <FileDropzone
            onDropFile={props.onChange}
            image={props.value}
            defaultImage={homeCarouselItem?.imageUrl}
            width="100%"
            height={300}
          />
        )}
      />
      {errors.image && <Alert severity="error">La imagen es obligatoria</Alert>}

      <Controller
        name="title"
        control={control}
        as={
          <TextField
            label="Título"
            placeholder="Ingresar título"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            autoComplete="off"
          />
        }
      />

      <Controller
        name="description"
        control={control}
        as={
          <TextField
            label="Descripción"
            placeholder="Ingresar descripción"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            autoComplete="off"
          />
        }
      />

      <Controller
        name="link"
        control={control}
        as={
          <TextField
            label="Link"
            placeholder="Ingresar link"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            autoComplete="off"
            helperText="Ingresar obligatoriamente http:// ó https:// según corresponda."
          />
        }
      />

      <Divider className={classes.divider} />

      <div className={classes.actions}>
        <Button
          onClick={() => push('/home')}
          variant="contained"
          color="secondary"
          className={classes.actionButton}
        >
          Salir
        </Button>
        <Button type="submit" variant="contained" color="primary" className={classes.actionButton}>
          {homeCarouselItem?.id ? 'Actualizar' : 'Guardar'}
        </Button>
        {isLoading && <CircularProgress disableShrink size={26} />}
      </div>
    </form>
  )
}
