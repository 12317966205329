import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: 20,
  },
  divider: {
    marginBottom: 20,
    marginTop: 20,
  },
  editor: {
    height: 200,
    marginBottom: 50,
  },
  formGroup: {
    display: 'flex',
    alignItems: 'center',
    '& .label': {
      marginRight: 30,
      marginLeft: 30,
    },
  },
  formControl: {
    marginRight: theme.spacing(3),
  },
  numericControl: {
    width: 70,
  },
  image: {
    maxWidth: '100%',
  },
  speakerItem: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.05)',
    },
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
  },
  actionsButton: {
    marginRight: 20,
  },
  zoomLink: {
    fontSize: '.9rem',
    color: '#666',
    textDecoration: 'none',
  },
}))

export { useStyles }
