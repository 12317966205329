import React, { FC } from 'react'
import { InputBase } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

import { useStyles } from './styles'

interface Props {
  handleTextChange: (inputText: string) => void
  placeholder: string
}

export const Search: FC<Props> = ({ handleTextChange, placeholder }) => {
  const classes = useStyles()

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        placeholder={placeholder}
        onChange={e => handleTextChange(e.target.value)}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ 'aria-label': 'search' }}
      />
    </div>
  )
}
