import React, { FC } from 'react'
import { Paper } from '@material-ui/core'
import { RouteComponentProps } from 'react-router'
import { useMutation, queryCache, useQuery } from 'react-query'

import { QUERY_KEYS } from '@api'
import { useCustomSnackbar } from '@hooks'
import { ErrorResponse } from '@types'

import { Header } from '../../components'
import { useStyles } from './styles'
import { UserForm } from '../components'
import { UserApi } from '../api'

export const UserDetails: FC<RouteComponentProps<{ id: string }>> = ({ match }) => {
  const { id } = match!.params
  const classes = useStyles()
  const snackbar = useCustomSnackbar()

  const { data: user } = useQuery([QUERY_KEYS.USER, id], UserApi.getLocalUser)

  const [update, { isLoading, isSuccess }] = useMutation(UserApi.updateUser, {
    onSuccess: () => {
      queryCache.invalidateQueries(QUERY_KEYS.USER)
      snackbar.showSuccess('¡El usuario se actualizó con éxito!')
    },
    onError: (error: ErrorResponse) => {
      snackbar.showError(error.message)
    },
  })

  return (
    <>
      <Header title="Editar usuario" />

      <Paper elevation={1} className={classes.paper}>
        {user && (
          <UserForm
            handleSubmit={userrPayload => {
              if (!user) return
              update({ id: user.id, ...userrPayload })
            }}
            user={user}
            isLoading={isLoading}
            isSuccess={isSuccess}
          />
        )}
      </Paper>
    </>
  )
}
