import React, { FC } from 'react'

import { Drawer } from './components'
import { useStyles } from './styles'

export const Layout: FC = ({ children }) => {
  const classes = useStyles()

  return (
    <>
      <Drawer />
      <main className={classes.main}>{children}</main>
    </>
  )
}
