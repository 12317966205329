import React from 'react'
import ReactQuill from 'react-quill'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import * as yup from 'yup'
import { Grid, TextField, Typography, Divider, Button, CircularProgress } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { yupResolver } from '@hookform/resolvers/yup'
import { useHistory } from 'react-router'
import { useGlobalStyles } from '@styles'
import { FileDropzone } from '@components'

import { SpeakerFormProps, SpeakerFormType } from './types'
import { useStyles } from './styles'

export const SpeakerForm: React.FC<SpeakerFormProps> = ({
  speaker,
  handleSubmit: submit,
  isLoading,
  isEdit = false,
}) => {
  const globalClasses = useGlobalStyles()
  const classes = useStyles()
  const { push } = useHistory()

  const validationSchema = yup.object().shape({
    name: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().email().required(),
    country: yup.string().required(),
    specialty: yup.string().required(),
    biography: yup.string(),
    profilePictureUrl: yup.string(),
    profilePicture: isEdit ? yup.mixed().optional() : yup.mixed().required(),
  })

  const defaultValues = {
    name: speaker?.name ?? '',
    lastName: speaker?.lastName ?? '',
    email: speaker?.email ?? '',
    country: speaker?.country ?? '',
    specialty: speaker?.specialty ?? '',
    biography: speaker?.biography ?? '',
    profilePictureUrl: speaker?.profilePictureUrl,
  }

  const { handleSubmit, errors, control } = useForm<SpeakerFormType>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })

  const onSubmit: SubmitHandler<SpeakerFormType> = data => {
    const { biography, ...payload } = data
    submit({ biography: biography !== '' ? biography : undefined, ...payload })
  }

  return (
    <form className={globalClasses.form} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item>
          <Typography variant="h5" className={classes.title}>
            Imagen de perfil
          </Typography>
          <Controller
            name="profilePicture"
            control={control}
            render={props => (
              <FileDropzone
                onDropFile={props.onChange}
                image={props.value}
                defaultImage={speaker?.profilePictureUrl}
                width={300}
                height={300}
              />
            )}
          />
          {errors.profilePicture && <Alert severity="error">La imagen es requerida</Alert>}
        </Grid>

        <Grid item md={9}>
          <Typography variant="h5" className={classes.title}>
            Datos personales
          </Typography>

          <Controller
            name="name"
            control={control}
            as={
              <TextField
                label="Nombre *"
                placeholder="Ingresar nombre"
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                autoComplete="off"
              />
            }
          />
          {errors.name && <Alert severity="error">Este campo es obligatorio</Alert>}

          <Controller
            name="lastName"
            control={control}
            as={
              <TextField
                label="Apellido *"
                placeholder="Ingresar appelido"
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                autoComplete="off"
              />
            }
          />
          {errors.lastName && <Alert severity="error">Este campo es obligatorio</Alert>}

          <Controller
            name="email"
            control={control}
            as={
              <TextField
                label="Email *"
                placeholder="Ingresar email"
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                autoComplete="off"
              />
            }
          />
          {errors.email && <Alert severity="error">Debe ingresar un email válido</Alert>}

          <Controller
            name="country"
            control={control}
            as={
              <TextField
                label="País *"
                placeholder="Ingresar país"
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                autoComplete="off"
              />
            }
          />
          {errors.country && <Alert severity="error">Este campo es obligatorio</Alert>}

          <Controller
            name="specialty"
            control={control}
            as={
              <TextField
                label="Especialidad *"
                placeholder="Ingresar especialidad"
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                autoComplete="off"
              />
            }
          />
          {errors.specialty && <Alert severity="error">Este campo es obligatorio</Alert>}
        </Grid>
      </Grid>

      <Typography className={classes.title}>Biografía</Typography>

      <Controller
        name="biography"
        control={control}
        render={props => (
          <ReactQuill value={props.value} onChange={props.onChange} className={classes.editor} />
        )}
      />
      {errors.biography && <Alert severity="error">Este campo es obligatorio</Alert>}

      <Divider className={classes.divider} />

      <div className={classes.actions}>
        <Button
          onClick={() => push('/speakers')}
          variant="contained"
          color="secondary"
          className={classes.actionButton}
        >
          Salir
        </Button>
        <Button variant="contained" color="primary" className={classes.actionButton} type="submit">
          {speaker?.id ? 'Actualizar' : 'Guardar'}
        </Button>
        {isLoading && <CircularProgress disableShrink size={26} />}
      </div>
    </form>
  )
}
