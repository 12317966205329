import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  header: {
    marginBottom: 40,
  },
  title: {
    marginRight: 'auto',
  },
})

export { useStyles }
