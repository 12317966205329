import { createMuiTheme, makeStyles } from '@material-ui/core'

export { COLORS } from './colors'

export type COLORS_ALERTS = 'success' | 'info' | 'warning' | 'error'

export const useGlobalStyles = makeStyles(theme => ({
  mediumBottomSeparator: {
    marginBottom: 20,
  },
  form: {
    width: '100%',
  },
  formGroup: {
    width: '100%',
    marginBottom: 20,
    '& > *': {
      flexGrow: 1,
    },
    '&::before': {
      borderColor: theme.palette.grey[100],
    },
  },
  formControl: {
    '& input': {
      width: '100%',
    },
  },
  inline: {
    display: 'inline',
  },
  progressBar: {
    position: 'fixed',
    zIndex: 9999,
    top: 0,
    right: 0,
    left: 0,
  },
  modal: {
    backgroundColor: 'transparent',
  },
  modalCard: {
    position: 'relative',
    backgroundColor: '#ffffff',
    width: 628,
    margin: '50px auto',
    padding: '26px 20px',
    boxShadow: '5px 5px 50px 0 rgba(0, 0, 0, 0.16)',
    borderRadius: 35,
  },

  modalCloseIcon: {
    cursor: 'pointer',
    position: 'absolute',
    top: '20px',
    right: '30px',
    color: theme.palette.primary.light,
  },
  modalContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '100vh',
    outline: 'none',
  },
}))

export const theme = createMuiTheme({
  typography: {
    fontFamily: `'Montserrat', sans-serif`,
  },
})
