import { makeStyles } from '@material-ui/core/styles'

import { COLORS } from '@styles'

const useStyles = makeStyles({
  greyLine: {
    '& td': { backgroundColor: COLORS.GRAY_LIGHT },
  },
})

export { useStyles }
