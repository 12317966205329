import React, { FC, useEffect, useState } from 'react'
import {
  Modal,
  Switch,
  Button,
  FormControlLabel,
  Fade,
  TextField,
  CircularProgress,
  Container,
  Card,
  CardContent,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { Alert } from '@material-ui/lab'
import { useForm } from 'react-hook-form'
import { queryCache, useMutation } from 'react-query'

import { useGlobalStyles } from '@styles'
import { ErrorResponse, Tag } from '@types'
import { QUERY_KEYS } from '@api'
import { useCustomSnackbar } from '@hooks'

import { TagsApi, UpdateTagPayload } from '../../api'
import { useStyles } from '../../styles'

interface Props {
  isOpen: boolean
  handleClose: () => void
  itemToEdit: Tag
}

export const EditTagModal: FC<Props> = ({ isOpen, itemToEdit, handleClose }) => {
  const classes = useStyles()
  const globalClasses = useGlobalStyles()
  const snackbar = useCustomSnackbar()

  const { id } = itemToEdit
  const [name, setName] = useState('')
  const [isActive, setIsActive] = useState(false)

  const { register, handleSubmit, errors } = useForm<Tag>()

  useEffect(() => {
    setName(itemToEdit?.name)
    setIsActive(itemToEdit?.isActive)
  }, [itemToEdit])

  const [update, { isLoading }] = useMutation(TagsApi.updateTag, {
    onSuccess: async () => {
      await queryCache.invalidateQueries(QUERY_KEYS.TAGS)
      snackbar.showSuccess('¡El item se actualizó con éxito!')
    },
    onError: (error: ErrorResponse) => {
      snackbar.showError(error.message)
    },
  })

  const onSubmit = (data: UpdateTagPayload) => {
    update(data)
  }

  return (
    <>
      <Modal open={isOpen} onClose={handleClose} className={globalClasses.modal}>
        <Fade in={isOpen} timeout={500}>
          <Container className={globalClasses.modalContainer}>
            <Card className={globalClasses.modalCard}>
              <CloseIcon
                className={globalClasses.modalCloseIcon}
                onClick={() => {
                  handleClose()
                }}
              />
              <CardContent>
                <form onSubmit={handleSubmit(() => onSubmit({ id, name, isActive }))}>
                  <div className={globalClasses.formGroup}>
                    <TextField
                      id="tagName"
                      label="Nombre"
                      placeholder="Ingresar nombre de etiqueta"
                      fullWidth
                      margin="normal"
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      value={name}
                      onChange={e => setName(e.target.value)}
                      autoComplete="off"
                      name="name"
                      inputRef={register({ required: true })}
                    />
                    {errors.name && <Alert severity="error">Esta campo es obligatorio</Alert>}
                  </div>
                  <div className={globalClasses.formGroup}>
                    <FormControlLabel
                      control={
                        <Switch
                          id="check-status"
                          checked={isActive}
                          onChange={() => setIsActive(prevIsActive => !prevIsActive)}
                          name="check-status"
                        />
                      }
                      label="Estado"
                    />
                  </div>
                  <div className={classes.actions}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.saveButton}
                    >
                      Editar etiqueta{' '}
                      {isLoading && (
                        <CircularProgress
                          className={classes.progress}
                          disableShrink
                          color="inherit"
                          size={18}
                        />
                      )}
                    </Button>
                  </div>
                </form>
              </CardContent>
            </Card>
          </Container>
        </Fade>
      </Modal>
    </>
  )
}
