import React, { FC, useMemo } from 'react'
import SearchIcon from '@material-ui/icons/Search'
import { InputBase } from '@material-ui/core'
import { NavLink, useLocation } from 'react-router-dom'

import { useStyles } from './styles'

interface Props {
  handleTextChange: (inputText: string) => void
}

export const Search: FC<Props> = ({ handleTextChange }) => {
  const classes = useStyles()
  const { search } = useLocation()

  const params = useMemo(() => new URLSearchParams(search), [search])

  return (
    <div className={classes.search}>
      <NavLink
        to={() => {
          params.delete('time')
          return {
            pathname: '/webinars',
            search: `?${params.toString()}`,
          }
        }}
        className={classes.navLink}
        isActive={() => {
          const p = new URLSearchParams(search)
          return !p.has('time')
        }}
        exact
      >
        TODOS
      </NavLink>
      <NavLink
        to={() => {
          params.set('time', 'past')
          return {
            pathname: '/webinars',
            search: `?${params.toString()}`,
          }
        }}
        className={classes.navLink}
        isActive={() => {
          const p = new URLSearchParams(search)
          return p.get('time') === 'past'
        }}
        exact
      >
        ANTERIORES
      </NavLink>
      <NavLink
        to={() => {
          params.set('time', 'future')
          return {
            pathname: '/webinars',
            search: `?${params.toString()}`,
          }
        }}
        className={classes.navLink}
        isActive={() => {
          const p = new URLSearchParams(search)
          return p.get('time') === 'future'
        }}
        exact
      >
        PRÓXIMOS
      </NavLink>
      <div className={classes.inputWrapper}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          placeholder="FILTRAR WEBINARS"
          onChange={e => handleTextChange(e.target.value)}
          classes={{
            input: classes.inputInput,
          }}
          inputProps={{ 'aria-label': 'buscar' }}
        />
      </div>
    </div>
  )
}
