import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  wrapper: {
    backgroundColor: '#EEE',
    height: '100vh',
    width: '100vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    padding: '30px',
  },
  formGroup: {
    marginBottom: '20px',
  },
  button: {
    width: '100%',
  },
})

export { useStyles }
