import React, { useMemo, useState } from 'react'
import { IconButton, Switch, Tooltip } from '@material-ui/core'
import { useHistory } from 'react-router'
import { queryCache, useMutation, useQuery } from 'react-query'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'

import { ErrorResponse, HomeCarouselItem } from '@types'
import { QUERY_KEYS, GlobalHomeCarouselItemsApi } from '@api'
import { useCustomSnackbar } from '@hooks'

import { Header, MuiTable } from '../../components'
import { RemoveHomeCarouselItemModal } from '../Components'
import { HomeCarouselApi } from './api'

const columns = [
  { label: 'Imagen', name: 'image' },
  { label: 'Título', name: 'title' },
  { label: 'Visible', name: 'status' },
  { label: 'Acciones', name: 'actions' },
]

export const HomeCarousel = () => {
  const { push } = useHistory()
  const snackbar = useCustomSnackbar()

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [limit] = useState(10)
  const [carouselItemSelected, setCarouselItemSelected] = useState<HomeCarouselItem | null>(null)
  const [IsRemoveHomeCarouselItemModalOpen, setIsRemoveHomeCarouselItemModalOpen] = useState(false)

  const { data: carouselItems } = useQuery(
    [QUERY_KEYS.HOME_CAROUSEL_ITEMS, { page, limit }],
    GlobalHomeCarouselItemsApi.getHomeCarouselItems,
  )

  const [update] = useMutation(HomeCarouselApi.updateStatus, {
    onSuccess: () => {
      queryCache.invalidateQueries(QUERY_KEYS.HOME_CAROUSEL_ITEMS)
      snackbar.showSuccess('¡Actualización exitosa!')
    },
    onError: (error: ErrorResponse) => {
      snackbar.showError(error.message)
    },
  })

  const carouselData = useMemo(
    () =>
      carouselItems?.map(carousel => ({
        ...carousel,
        image: <img src={carousel.imageUrl} alt={carousel.title} height="50" />,
        status: (
          <Tooltip title="Cambiar estado" placement="top">
            <Switch
              id={`check${carousel.id}`}
              checked={carousel.isActive}
              onChange={() => update({ ...carousel, newStatus: !carousel.isActive })}
              name={`check${carousel.id}`}
              value={carousel.id}
            />
          </Tooltip>
        ),
        actions: (
          <div key={carousel?.title}>
            <Tooltip title="Editar" placement="top">
              <IconButton
                color="primary"
                aria-label="Editar"
                component="span"
                onClick={() => push(`/home/edit/${carousel.id}`)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Eliminar" placement="top">
              <IconButton
                color="secondary"
                aria-label="Eliminar"
                component="span"
                onClick={() => {
                  setCarouselItemSelected(carousel)
                  setIsRemoveHomeCarouselItemModalOpen(true)
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        ),
      })),
    [carouselItems, push, update],
  )

  return (
    <>
      <Header title="Carrusel items Home">
        <Tooltip title="Agregar nuevo Item al carrusel" placement="top">
          <IconButton
            color="primary"
            aria-label="agregar nuevo item al carrusel"
            component="span"
            onClick={() => push('/home/add')}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      </Header>
      <RemoveHomeCarouselItemModal
        isOpen={IsRemoveHomeCarouselItemModalOpen}
        handleClose={() => setIsRemoveHomeCarouselItemModalOpen(false)}
        id={carouselItemSelected?.id!}
      />
      <MuiTable
        title=""
        data={carouselData ?? []}
        columns={columns}
        options={{
          page,
          rowsPerPage,
          serverSide: true,
          onChangePage: setPage,
          onChangeRowsPerPage: setRowsPerPage,
          sort: false,
        }}
      />
    </>
  )
}
