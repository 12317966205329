export type Admin = {
  id: number
  email: string
  password: string
}

export type UserCache = Omit<Admin, 'id'>

export type Tag = {
  id: number
  name: string
  isActive: boolean
}

export type FormMedica = {
  id: number
  mailTo: string
  mailFrom: string
  subject: string
  request: string
  sendMeACopy: number
  user: User
  therapeuticAreas: TherapeuticArea
  created_at: string
}

export type Speaker = {
  id: number
  name: string
  lastName: string
  email: string
  country: string
  specialty: string
  biography: string
  profilePictureUrl: string
  isActive: boolean
}

export type User = {
  id: number
  name: string
  lastName: string
  email: string
  status: string
  newStatus: string
  specialty: string
  country: string
  title: string
  isVerified: boolean
  password?: string
  updated_at?: string
  created_at: string
}

export enum WebinarStatus {
  pending = 'pending',
  completed = 'completed',
  draft = 'draft',
  discarded = 'discarded',
  finalized = 'finalized',
  started = 'started',
}

export enum UserStatus {
  toModerate = 'toModerate',
  accepted = 'accepted',
  rejected = 'rejected',
}
export interface Webinar {
  id: number
  date: string
  duration: number
  title: string
  description: string
  videoId: string
  imageUrl: string
  therapeuticArea: TherapeuticArea
  zoomId: number
  joinUrl: string
  password: string
  status: WebinarStatus
  isSalient: boolean
  isActive: boolean
  created_at: string
  updated_at: string
  tags: Tag[]
  speakers: Speaker[]
  registrants: Registrant[]
}

export enum RegistrantOrigin {
  web = 'web',
  zoom = 'zoom',
}

export interface Registrant {
  name: string
  lastName: string
  email: string
  hasParticipated?: boolean
  origin?: RegistrantOrigin
}

export type TherapeuticArea = {
  id: number
  name: string
  isActive: boolean
}

export type HomeCarouselItem = {
  id: number
  title: string
  description: string
  link: string
  isActive: boolean
  imageUrl: string
}

export type ErrorResponse = {
  message: string
  status: number
  validation: {}
}

export type CacheUser = { user: Admin; token: string }

export interface LoginResponse {
  token: string
  user: Admin
}
