import React, { FC } from 'react'
import { useHistory } from 'react-router'
import { Controller, useForm } from 'react-hook-form'
import { Button, Card, CircularProgress, TextField } from '@material-ui/core'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Alert } from '@material-ui/lab'

import { useAuth } from '@hooks'

import { useStyles } from './styles'
import { LoginFormType } from './types'

const validationSchema = yup.object().shape({
  email: yup.string().required(),
  password: yup.string().required(),
})

const Login: FC = () => {
  const classes = useStyles()

  const { push } = useHistory()
  const { login, isLoading } = useAuth()

  const defaultValues = {
    email: '',
    password: '',
  }

  const { control, handleSubmit, errors } = useForm<LoginFormType>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })

  const onSubmit = async (loginData: LoginFormType) => {
    await login(loginData)
    push('/webinars')
  }

  return (
    <div className={classes.wrapper}>
      <Card className={classes.card}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.formGroup}>
            <Controller
              name="email"
              control={control}
              as={
                <TextField
                  label="Email"
                  placeholder="Ingresar correo electrónico"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  autoComplete="off"
                  size="small"
                />
              }
            />
            {errors.email && <Alert severity="error">El email es obligatorio</Alert>}
          </div>

          <div className={classes.formGroup}>
            <Controller
              name="password"
              control={control}
              as={
                <TextField
                  label="Contraseña"
                  type="password"
                  placeholder="Ingresar contraseña"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  autoComplete="off"
                  size="small"
                />
              }
            />
            {errors.email && <Alert severity="error">El email es obligatorio</Alert>}
          </div>
          <Button type="submit" variant="contained" color="primary" className={classes.button}>
            Inicar sesión
            {isLoading && (
              <CircularProgress
                disableShrink
                color="inherit"
                size={20}
                style={{ marginLeft: 15 }}
              />
            )}
          </Button>
        </form>
      </Card>
    </div>
  )
}

export default Login
