import React, { FC } from 'react'
import { Box, Typography } from '@material-ui/core'

import { useStyles } from './styles'

interface Props {
  title: string
}

export const Header: FC<Props> = ({ children, title }) => {
  const classes = useStyles()

  return (
    <header>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className={classes.header}
      >
        <Typography variant="h4" color="textPrimary" className={classes.title}>
          {title}
        </Typography>

        {children}
      </Box>
    </header>
  )
}
