import React, { FC } from 'react'
import { Route, Switch, RouteComponentProps } from 'react-router-dom'

import { HomeCarousel } from './HomeCarousel'
import { AddHomeCarousel } from './AddHomeCarousel'
import { HomeCarouselDetails } from './HomeCarouselDetails'

export const HomeCarouselRouter: FC<RouteComponentProps> = ({ match }) => {
  return (
    <Switch>
      <Route exact path={match.path} component={HomeCarousel} />
      <Route exatc path={`${match.path}/add`} component={AddHomeCarousel} />
      <Route exatc path={`${match.path}/edit/:id`} component={HomeCarouselDetails} />
    </Switch>
  )
}
