import { api } from '@utils'

import { AddUserPayload } from '../types'
import { AddUserResponse } from './types'

const addUser = async (user: AddUserPayload): Promise<AddUserResponse> => {
  try {
    const response = await api.post<AddUserResponse>('/users', { user })
    return response.data
  } catch (err) {
    throw err.response.data.error
  }
}

export const AddUsersApi = { addUser }
