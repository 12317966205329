import { makeStyles } from '@material-ui/core'

const avatarSize = 110
const useStyles = makeStyles({
  avatar: {
    height: avatarSize,
    width: avatarSize,
    margin: '0',
  },
  name: {
    fontWeight: 'bold',
    fontSize: 22,
  },
  email: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: 10,
    },
  },
})

export { useStyles }
