import { api } from '@utils'

import { AddTagPayload } from './types'

const addTag = async (payload: AddTagPayload): Promise<void> => {
  try {
    const response = await api.post('/tags', { tag: payload })
    return response.data
  } catch (err) {
    throw err.response.data.error
  }
}

export const addTagApi = { addTag }
