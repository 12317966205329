import React, { FC } from 'react'
import {
  Modal,
  Button,
  Fade,
  CircularProgress,
  Container,
  Card,
  CardContent,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useForm } from 'react-hook-form'
import { queryCache, useMutation } from 'react-query'
import { useGlobalStyles } from '@styles'
import { ErrorResponse, User } from '@types'
import { QUERY_KEYS } from '@api'
import { useCustomSnackbar } from '@hooks'

import { useStyles } from '../../styles'
import { UserApi } from '../../api'
import { UpdateUserPayload } from '../../types'

interface Props {
  isOpen: boolean
  handleClose: () => void
  user: User
}

export const ValidateModal: FC<Props> = ({ isOpen, user, handleClose }) => {
  const classes = useStyles()
  const globalClasses = useGlobalStyles()
  const snackbar = useCustomSnackbar()

  const { handleSubmit } = useForm<User>()

  const [update, { isLoading }] = useMutation(UserApi.updateUser, {
    onSuccess: async () => {
      await queryCache.invalidateQueries(QUERY_KEYS.USER)
      snackbar.showSuccess(`${user?.name} ${user?.lastName} fue validado con éxito`)
      handleClose()
    },
    onError: (error: ErrorResponse) => {
      snackbar.showError(error.message)
    },
  })

  const onSubmit = (data: UpdateUserPayload) => {
    update(data)
  }

  return (
    <>
      <Modal open={isOpen} onClose={handleClose} className={globalClasses.modal}>
        <Fade in={isOpen} timeout={500}>
          <Container className={globalClasses.modalContainer}>
            <Card className={globalClasses.modalCard}>
              <CloseIcon
                className={globalClasses.modalCloseIcon}
                onClick={() => {
                  handleClose()
                }}
              />
              <CardContent>
                <form onSubmit={handleSubmit(() => onSubmit({ id: user.id, isVerified: true }))}>
                  <div className={globalClasses.formGroup}>
                    <p>{`¿Desea validar a ${user?.name} ${user?.lastName}?`}</p>
                  </div>
                  <div className={classes.actions}>
                    <Button
                      onClick={handleClose}
                      variant="contained"
                      color="secondary"
                      className={classes.saveButton}
                    >
                      Cancelar{' '}
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.saveButton}
                    >
                      Sí{' '}
                      {isLoading && (
                        <CircularProgress
                          className={classes.progress}
                          disableShrink
                          color="inherit"
                          size={18}
                        />
                      )}
                    </Button>
                  </div>
                </form>
              </CardContent>
            </Card>
          </Container>
        </Fade>
      </Modal>
    </>
  )
}
