import React, { FC } from 'react'
import {
  Snackbar,
  Button,
  SnackbarProps as SnackbarPropsType,
  ButtonProps as ButtonPropsType,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'

const CustomSnackbar: FC<{
  message?: string
  action?: string
  ButtonProps?: Partial<ButtonPropsType>
  SnackbarProps: Partial<SnackbarPropsType>
  customParameters: {
    type: 'error' | 'warning' | 'info' | 'success'
  }
}> = ({ message, action, ButtonProps, SnackbarProps, customParameters }) => {
  return (
    <Snackbar autoHideDuration={3000} {...SnackbarProps}>
      <Alert
        severity={customParameters?.type}
        variant="filled"
        action={
          action && (
            <Button color="inherit" size="small" {...ButtonProps}>
              {action}
            </Button>
          )
        }
      >
        {message}
      </Alert>
    </Snackbar>
  )
}

export { CustomSnackbar }
