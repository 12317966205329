import React, { FC } from 'react'
import { Route, Switch, RouteComponentProps } from 'react-router-dom'

import { Webinars } from './Webinars'
import { AddWebinar } from './AddWebinar'
import { WebinarDetails } from './WebinarDetails'

export const WebinarsRouter: FC<RouteComponentProps> = ({ match }) => {
  return (
    <Switch>
      <Route exact path={match.path} component={Webinars} />
      <Route exact path={`${match.path}/add`} component={AddWebinar} />
      <Route exatc path={`${match.path}/edit/:id`} component={WebinarDetails} />
    </Switch>
  )
}
