import { api } from '@utils'
import { User } from '@types'

import { GetUserResponse } from './types'

interface GetUsersResponse {
  users: User[]
  count: number
}

export interface GetUsersListFile {
  file: {
    type: 'Buffer'
    data: number[]
  }
}

export type UpdateUserPayload = RequiredKeys<Partial<User>, 'id'> & {
  changePasswordSwhitch?: boolean
}

interface GetUsersPayload {
  page?: number
  limit?: number
  name?: string
  lastName?: string
  status?: string | null
}

const getUsers = async (
  _: string,
  { page = 0, limit = 10, name = '', lastName = '', status = '' }: GetUsersPayload,
): Promise<GetUsersResponse> => {
  const params = {
    name: name || undefined,
    lastName: lastName || undefined,
    status: status || undefined,
    offset: page * limit,
    limit,
  }
  const response = await api.get<GetUsersResponse>('/users', { params })

  return response.data
}

const getUser = async (_: string, id: number) => {
  const response = await api.get<GetUserResponse>(`/users/${id!}`)
  return response.data.user
}

const getLocalUser = async (_: string, id: number) => {
  const response = await api.get<GetUserResponse>(`/users/${id!}/local`)
  return response.data.user
}

const updateUser = async ({ id, changePasswordSwhitch, ...payload }: UpdateUserPayload) => {
  try {
    const response = await api.put(`/users/${id}`, { user: payload })
    return response.data
  } catch (err) {
    throw err.response.data.error
  }
}

const downloadList = async ({
  name = '',
  lastName = '',
  status = '',
}: GetUsersPayload): Promise<GetUsersListFile> => {
  const params = {
    name: name || undefined,
    lastName: lastName || undefined,
    status: status || undefined,
  }
  const response = await api.get<GetUsersListFile>('/users/csv', { params })

  return response.data
}

export const UserApi = { getUsers, getUser, updateUser, downloadList, getLocalUser }
