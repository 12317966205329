import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  box: {
    display: 'flex',
    flexFlow: 'wrap',
    width: '100%',
  },
})

export { useStyles }
