import FormData from 'form-data'

import { api } from '@utils'

import { AddWebinarPayload, AddWebinarResponse } from './types'

const addWebinar = async ({
  image,
  ...payload
}: AddWebinarPayload): Promise<AddWebinarResponse> => {
  const formData = new FormData()
  formData.append('webinar', JSON.stringify(payload))

  try {
    const response = await api.post<AddWebinarResponse>('/webinars', formData)
    return response.data
  } catch (err) {
    throw err.response.data.error
  }
}

export const AddWebinarsApi = { addWebinar }
