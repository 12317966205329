import { api } from '@utils'
import { Tag } from '@types'

export type UpdateTagPayload = RequiredKeys<Partial<Tag>, 'id'>

const updateTag = async ({ id, ...payload }: UpdateTagPayload) => {
  try {
    const response = await api.put(`/tags/${id}`, { tag: payload })
    return response.data
  } catch (err) {
    throw err.response.data.error
  }
}

export const TagsApi = { updateTag }
