import React, { useState, useMemo, FC, useEffect } from 'react'
import { Tooltip, IconButton } from '@material-ui/core'
import { useQuery, useMutation, queryCache } from 'react-query'
import { useHistory, useLocation } from 'react-router'
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add'
import VisibilityIcon from '@material-ui/icons/Visibility'
import GetApp from '@material-ui/icons/GetApp'
import moment from 'moment'

import { QUERY_KEYS } from '@api'
import { useCustomSnackbar } from '@hooks'
import { User } from '@types'
import { STRINGS } from '@constants'

import { MuiTable, Header } from '../../components'
import { UserApi, GetUsersListFile } from '../api'
import { AcceptModal, UserModal, RejectModal, NavFilter } from '../components'
import { ValidateModal } from '../components/ValidateModal'

interface DownloadResponse {
  file: { data: ArrayBuffer }
}
const renderStatus = (status: string) => {
  switch (status) {
    case 'toModerate':
      return 'En Moderación'
    case 'accepted':
      return 'Aceptado'
    case 'rejected':
      return 'Rechazado'
    default:
      return null
  }
}
const columns = [
  { label: 'Apellido', name: 'lastName' },
  { label: 'Nombre', name: 'name' },
  { label: 'Estado ', name: 'status' },
  { label: 'Fecha de última modificación', name: 'updatedAt' },
  { label: 'Acciones', name: 'actions' },
]

export const Users: FC = () => {
  const history = useHistory()
  const snackbar = useCustomSnackbar()

  const [isInfoUserModalOpen, setIsInfoUserModalOpen] = useState(false)
  const [isValidateUserModalOpen, setIsValidateUserModalOpen] = useState(false)
  const [isAcceptUserModalOpen, setIsAcceptUserModalOpen] = useState(false)
  const [isRejectUserModalOpen, setIsRejectUserModalOpen] = useState(false)
  const [UserSelected, setUserSelected] = useState<User | null>(null)
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [search, setSearch] = useState('')
  const [userStatus, setUserStatus] = useState<string | null>(null)
  const { search: reactRouterSearch } = useLocation()
  const params = useMemo(() => new URLSearchParams(reactRouterSearch), [reactRouterSearch])

  const { data: users } = useQuery(
    [
      QUERY_KEYS.USER,
      { page, limit: rowsPerPage, name: search, lastName: search, status: userStatus },
    ],
    UserApi.getUsers,
  )

  const [downloadList] = useMutation(UserApi.downloadList, {
    onSuccess: (data: GetUsersListFile) => {
      queryCache.invalidateQueries(QUERY_KEYS.USER)
      const byteArray = new Uint8Array(data.file.data)
      const a = window.document.createElement('a')

      a.href = window.URL.createObjectURL(
        new Blob([byteArray], {
          type: 'text/csv;charset=utf-8',
        }),
      )
      a.download = 'listado_de_usuarios'

      document.body.appendChild(a)
      a.click()

      document.body.removeChild(a)
      snackbar.showSuccess('¡Lista descargada con éxito!')
    },
    onError: () => {
      snackbar.showError('No se encontraron usuarios...')
    },
  })

  useEffect(() => {
    setUserStatus(params.get('status'))
    setPage(0)
  }, [params, search])

  const usersData = useMemo(
    () =>
      users?.users.map(user => ({
        ...user,
        status: renderStatus(user.status),
        updatedAt: moment(user?.updated_at).format(STRINGS.DATE_FORMAT),
        actions: (
          <div key={user?.name}>
            <Tooltip title="Ver" placement="top">
              <IconButton
                color="primary"
                aria-label=""
                component="span"
                onClick={() => {
                  setUserSelected(user)
                  setIsInfoUserModalOpen(true)
                }}
              >
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
            {/* {user.isVerified ? (
              <>
                {user.status === 'accepted' ? (
                  <Tooltip title="Rechazar usuario" placement="top">
                    <IconButton
                      color="secondary"
                      aria-label="Rechazar usuario"
                      component="span"
                      onClick={() => {
                        setUserSelected(user)
                        setIsRejectUserModalOpen(true)
                      }}
                    >
                      <BlockIcon />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Verificar usuario" placement="top">
                    <IconButton
                      color="primary"
                      aria-label="Verificar usuario"
                      component="span"
                      onClick={() => {
                        setUserSelected(user)
                        setIsAcceptUserModalOpen(true)
                      }}
                    >
                      <CheckIcon />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title="Editar usuario" placement="top">
                  <IconButton
                    color="primary"
                    aria-label="Editar usuario"
                    component="span"
                    onClick={() => history.push(`/users/edit/${user.id}`)}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </>
            ) : (
              <>
                <Tooltip title="Validar usuario" placement="top">
                  <IconButton
                    color="primary"
                    aria-label="Validar usuario"
                    component="span"
                    onClick={() => {
                      setUserSelected(user)
                      setIsValidateUserModalOpen(true)
                    }}
                  >
                    <AssignmentTurnedInIcon />
                  </IconButton>
                </Tooltip>
              </>
            )} */}
            <>
            <Tooltip title="Editar usuario" placement="top">
                  <IconButton
                    color="primary"
                    aria-label="Editar usuario"
                    component="span"
                    onClick={() => history.push(`/users/edit/${user.id}`)}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                </>
          </div>
        ),
      })),
    [history, users],
  )

  const onHandleChangeSearch = (text: string) => {
    setPage(0)
    setSearch(text)
  }

  return (
    <>
      <Header title="Usuarios">
        <Tooltip title="Agregar nuevo usuario" placement="top">
          <IconButton
            color="primary"
            aria-label="agregar nuevo usuario"
            component="span"
            onClick={() => history.push('/users/add')}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Descargar lista de usuarios" placement="top">
          <IconButton
            color="primary"
            aria-label="Descargar ususarios"
            component="span"
            onClick={() => downloadList({ name: search, lastName: search, status: userStatus })}
          >
            <GetApp />
          </IconButton>
        </Tooltip>
      </Header>
      <NavFilter handleTextChange={onHandleChangeSearch} />
      <UserModal
        isOpen={isInfoUserModalOpen}
        handleClose={() => setIsInfoUserModalOpen(false)}
        user={UserSelected}
      />
      <AcceptModal
        isOpen={isAcceptUserModalOpen}
        handleClose={() => setIsAcceptUserModalOpen(false)}
        user={UserSelected!}
      />
      <ValidateModal
        isOpen={isValidateUserModalOpen}
        handleClose={() => setIsValidateUserModalOpen(false)}
        user={UserSelected!}
      />
      <RejectModal
        isOpen={isRejectUserModalOpen}
        handleClose={() => setIsRejectUserModalOpen(false)}
        user={UserSelected!}
      />

      <MuiTable
        title=""
        data={usersData ?? []}
        columns={columns}
        options={{
          page,
          count: users?.count ?? 0,
          serverSide: true,
          onChangePage: setPage,
          onChangeRowsPerPage: numberOfRows => {
            setPage(0)
            setRowsPerPage(numberOfRows)
          },
          sort: false,
        }}
      />
    </>
  )
}
